import { OnInit, Component, Input, Renderer2, ElementRef, Inject, NgModule, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Plugins } from '@capacitor/core';
import { environment } from 'src/environments/environment';
import { IonInput, ModalController } from '@ionic/angular';

const { Geolocation, Network } = Plugins;
declare var google : any;

@Component({
  selector: 'app-google-maps',
  templateUrl: './google-maps.component.html',
  styleUrls: ['./google-maps.component.scss'],
})
export class GoogleMapsComponent implements OnInit {

  public static initted: boolean = false;


  //These are variables that we might need to use
  public selected_latitude: number = -7.363025299999999;
  public selected_longitude: number = 112.7586772;
  public selected_address: string = "";
  //This is setted at our geocodeLatLng ( if returned kodepos is different then we will change the prov, kab, kec, kel, kodepos )
  public selected_kodepos: string = "";
  public selected_kelurahan: string = "";
  private _user_real_location: any;
  public get user_real_location(): any{
    return this._user_real_location;
  }

  //Search Element
  @ViewChild(IonInput) SearchInput: IonInput;

  public on_close(){
    this.modalCtrl.dismiss( null, "cancel", "GoogleMap" );
  }

 
  on_current_location(){
    Geolocation.getCurrentPosition().then((position) => {
      let latLng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
      this.geocodeLatLng( position.coords.latitude, position.coords.longitude );
    });
  }

  on_select_location(){
    //Close and return everything ( selected_latitude and selected_longitude )
    this.modalCtrl.dismiss( { 
      latitude: this.selected_latitude, 
      longitude: this.selected_longitude,
      address: this.selected_address,
      kodepos: this.selected_kodepos,
      kelurahan: this.selected_kelurahan
    }, "ok", "GoogleMap" );
  }



  //All necessary variables are located below

  //@Input('apiKey') apiKey: string;
  private apiKey: string = environment.gmap_api_key;

  private map: any;
  private geocoder: any;
  private infowindow: any;
  private searchinput: any;
  private searchbox: any;

  public markers: any[] = [];
  private mapsLoaded: boolean = false;
  private networkHandler = null;


  constructor(
    private renderer: Renderer2, @Inject(DOCUMENT) private _document,
    private modalCtrl: ModalController
  ) { 

  }

  ngOnInit() {
    this.init().then((res) => {
      //console.log("Google Maps ready.")
    }, (err) => {    
        console.log(err);
    });
    console.log( this.selected_address );
  }

  private init(): Promise<any> {

    return new Promise((resolve, reject) => {

        this.loadSDK().then((res) => {

            this.initMap().then((res) => {
                resolve(true);
            }, (err) => {
                reject(err);
            });

        }, (err) => {

            reject(err);

        });

    });

  }

  private loadSDK(): Promise<any> {

      //console.log("Loading Google Maps SDK");

      return new Promise((resolve, reject) => {

          if(!this.mapsLoaded){

              Network.getStatus().then((status) => {

                  if(status.connected){

                      this.injectSDK().then((res) => {
                          resolve(true);
                      }, (err) => {
                          reject(err);
                      });

                  } else {

                      if(this.networkHandler == null){

                          this.networkHandler = Network.addListener('networkStatusChange', (status) => {

                              if(status.connected){

                                  this.networkHandler.remove();

                                  this.init().then((res) => {
                                      //console.log("Google Maps ready.")
                                  }, (err) => {    
                                      console.log(err);
                                  });

                              }

                          });

                      }

                      reject('Not online');
                  }

              }, (err) => {

                  // NOTE: navigator.onLine temporarily required until Network plugin has web implementation
                  if(navigator.onLine){

                      this.injectSDK().then((res) => {
                          resolve(true);
                      }, (err) => {
                          reject(err);
                      });

                  } else {
                      reject('Not online');
                  }

              });

          } else {
              reject('SDK already loaded');
          }

      });


  }

  private injectSDK(): Promise<any> {

      return new Promise((resolve, reject) => {
          if( GoogleMapsComponent.initted == false ){
            window['mapInit'] = () => {
                GoogleMapsComponent.initted = true;
                resolve(true);
            }

            let script = this.renderer.createElement('script');
            script.id = 'googleMaps';

            if(this.apiKey){
                script.src = 'https://maps.googleapis.com/maps/api/js?key=' + this.apiKey + '&libraries=places&callback=mapInit';
            } else {
                script.src = 'https://maps.googleapis.com/maps/api/js?callback=mapInit';       
            }

            this.renderer.appendChild(this._document.body, script);
          }else{
            resolve(true);
          }
      });

  }

  private initMap(): Promise<any> {

    return new Promise((resolve, reject) => {
          let use_default: boolean = false;
          if( this.selected_longitude == 0 && this.selected_latitude == 0 ){
            this.selected_latitude = -7.363025299999999;
            this.selected_longitude = 112.7586772;
            use_default = true;
          }
          let latLng = new google.maps.LatLng(this.selected_latitude, this.selected_longitude);

          let mapOptions = {
              center: latLng,
              zoom: 12,
              disableDefaultUI: true, clickableIcons: false
          };
          this.map = new google.maps.Map( document.getElementById("map") as HTMLElement , mapOptions);
          this.geocoder = new google.maps.Geocoder();
          if( use_default && this.selected_address && this.selected_address.length > 5 ){
            //Search the address inputted IF one is available
            this.geocoder.geocode({ 'address': this.selected_address }).then( _data => {
              if( _data.results && _data.results.length > 0 ){
                this.geocodeLatLng( _data.results[0].geometry.location.lat(), _data.results[0].geometry.location.lng() );
              }
            });
          }
          
          
          this.infowindow = new google.maps.InfoWindow();
          //Create ON CLICK call geocodeLatLng which will drop marker and record coordinate and address
          this.map.addListener( 'click', (event) => {
            this.geocodeLatLng( event.latLng.lat(), event.latLng.lng() );
          } );
          // Create the search box and link it to the UI element.
          this.searchinput = document.getElementById("pac-input") as HTMLInputElement;
          this.searchbox = new google.maps.places.SearchBox(this.searchinput);
          this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(this.searchinput);
          
          // Bias the SearchBox results towards current map's viewport.
          this.map.addListener("bounds_changed", () => {
            this.searchbox.setBounds(this.map.getBounds());
          });
          this.searchbox.addListener("places_changed", () => {
            const places = this.searchbox.getPlaces();
        
            if (places.length == 0) {
              return;
            }
        
            // Clear out the old markers.
            this.clear_marker();
        
            // For each place, get the icon, name and location.
            const bounds = new google.maps.LatLngBounds();
            places.forEach((place) => {
              if (!place.geometry || !place.geometry.location) {
                console.log("Returned place contains no geometry");
                return;
              }
        
              // Create a marker for each place.
              this.map.setZoom(17);
              const marker = new google.maps.Marker({
                map: this.map,
                title: place.name,
                position: place.geometry.location,
              })
              this.markers.push(marker);
              this.selected_latitude = place.geometry.location.lat();
              this.selected_longitude = place.geometry.location.lng();
              this.selected_address = place.formatted_address;
              this.infowindow.setContent(place.formatted_address);
              this.infowindow.open(this.map, marker);
        
              if (place.geometry.viewport) {
                // Only geocodes have viewport.
                bounds.union(place.geometry.viewport);
              } else {
                bounds.extend(place.geometry.location);
              }
            });
            this.map.fitBounds(bounds);


            
            resolve(true);

          }, (err) => {
              console.log( err );
              reject('Could not initialise map');

          });

          //Retrieve info and center to our selected_latitude and longitude
          this.geocodeLatLng( this.selected_latitude, this.selected_longitude );
    });
  }

  public geocodeLatLng(lat: number, lng: number): void{
    let latlng = {
      lat: lat, lng: lng
    }
    this.geocoder.geocode(
      { location: latlng },
      (
        results: any,
        status: any
      ) => {
        this.clear_marker();
        if (status === "OK") {
          if (results[0]) {
            this.map.setZoom(17);
            const marker = new google.maps.Marker({
              map: this.map,
              animation: google.maps.Animation.DROP,
              position: latlng
            });
            
            for( let single_address of results[0].address_components ){
              if( single_address.types[0] == "postal_code" ){
                this.selected_kodepos = single_address.long_name;
              }else if( single_address.types[0] == "administrative_area_level_4" ){
                this.selected_kelurahan = single_address.long_name;
              }
            }
            this.selected_latitude = lat;
            this.selected_longitude = lng;
            this.selected_address = results[0].formatted_address;
            this.infowindow.setContent(results[0].formatted_address);
            this.infowindow.open(this.map, marker);
            this.markers.push( marker );
          } else {
            window.alert("No results found");
          }
        } else {
          window.alert("Geocoder failed due to: " + status);
        }
      }
    );
  }

  //Clear Marker on every marker placement
  private clear_marker(){
    this.markers.forEach((marker) => {
      marker.setMap(null);
    });
    this.markers = [];
  }

}
