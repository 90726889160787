import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { GroupService } from './group.service';
import { group, result_general, result_modified } from './interfaces.service';

/**
 * We will only have the variables global and result_global for on this service, because we will only used them here
 */
interface global {
  variable_data_id: string;
  var_type: string;
  data: string;
}
interface setup {
  feature_id: string;
  name: string;
  value: string;
}
interface invoice_form_setup{
  followup: follow_up_status[];
  status: follow_up_status[];

  message_all_oos?: string;
  message_some_oos?: string;
  message_no_oos?: string;
}
export interface follow_up_status{
  id: string;
  name: string;
  message: string;
  action: string;
}
interface template {
  type: string;
  name: string;
  action?: string;
  message?: string;
}
interface result_global extends result_general {
  data: {
    global: global[],
    setup: setup[]
  }
}


@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  //Use this for our Register / Account Profile
  //20210516 :: Will be loaded later on our LoadAll
  public kategori_usaha_array: group[] = [
    {
      group_id: "3347_33",
      group_name_id: "3347_11",
      group_name: "Kategori Usaha",
      name: "Koperasi"
    },
    {
      group_id: "3347_34",
      group_name_id: "3347_11",
      group_name: "Kategori Usaha",
      name: "UMKM"
    },
    {
      group_id: "3347_35",
      group_name_id: "3347_11",
      group_name: "Kategori Usaha",
      name: "Besar"
    }
  ];
  public bentuk_pendidikan_array: group[] = [

  ];
  public status_pendidikan_array: group[] = [
  
  ];
  


  constructor( private http: HttpClient ) {
  }


  //Load all page with proper filter and cms_type
  public globals: global[]; 
  public setups: setup[];
  public is_loaded: boolean = false;
  Load(): Observable<result_global>{
    if( this.is_loaded ){
      return null;
    }
    this.is_loaded = true;
    let params = new HttpParams();
    return this.http.get<result_global>( environment.api_url + "/global", 
      { params: params } 
    ).pipe( tap( data => {
      this.is_loaded = true;
      console.log( data );
      if( data.data.global != null ){
        this.globals = data.data.global;
        this.setups = data.data.setup;

        //Set all necessary Global Variables ( THE JSON )
        //console.log( data );
        for( let global of this.globals ){
          if( global.var_type == "[JSON] Invoice Setup" ){
            try{
              this.invoice_setup = JSON.parse( global.data.replace(/\'/g, '"') );
            }catch( e ){
              this.invoice_setup = {
                'followup': [
                    { 
                        'id':'f1',
                        'name':'Pertama',
                        'message':'Template Follow Up 1',
                        'action':'email,wa'
                    },
                    { 
                        'id':'f2',
                        'name':'Kedua',
                        'message':'Template Follow Up 2',
                        'action':'email,wa'
                    },
                    { 
                        'id':'f3',
                        'name':'Ketiga',
                        'message':'Template Follow Up 3',
                        'action':'email,wa'
                    },
                    { 
                        'id':'f4',
                        'name':'Keempat',
                        'message':'Template Follow Up 4',
                        'action':'email,wa'
                    }
                ],
                'status': [
                    {
                        'id':'new',
                        'name':'Transaksi Baru',
                        'message':'',
                        'action':'paid,completed,cancelled'
                    },
                    {
                        'id':'confirmation',
                        'name':'Konfirmasi Pembayaran',
                        'message':'',
                        'action':'paid,completed,cancelled'
                    },
                    {
                        'id':'paid',
                        'name':'Pembayaran Diterima',
                        'message':'',
                        'action':'completed,cancelled'
                    },
                    {
                        'id':'completed',
                        'name':'Transaksi Selesai',
                        'message':'',
                        'action':''
                    },
                    {
                        'id':'cancelled',
                        'name':'Transaksi Dibatalkan',
                        'message':'',
                        'action':''
                    }
                ],
                'message_all_oos': 'Dari keseluruhan order anda yang sudah kami proses, semua produk Out of Stock! Invoice ini akan di cancel!',
                'message_no_oos': 'Dari keseluruhan order anda yang sudah kami proses, tidak ada produk yang Out of Stock (OOS)',
                'message_some_oos': 'Dari keseluruhan order anda yang sudah kami proses, dengan detail produk yang Out of Stock (OOS) sbb : ~oos_message~ . Total Refund adalah Rp ~refund~ , Balance Rp ~wallet_balance~Informasi lengkap mengenai wallet anda dapat di cek dengan login kembali di website kami. Nilai di wallet akan otomatis menjadi nilai pengurang untuk orderan anda selanjutnya atau bisa click tombol Refund ke Rekening di wallet tersebut.'
              };
            }
          }else if( global.var_type == "[JSON] Form Setup" ){
            try{
              this.form_setup = JSON.parse( global.data.replace(/\'/g, '"') );
            }catch( e ){
              this.form_setup =  {
                  'followup': [
                      { 
                          'id':'f1',
                          'name':'Pertama',
                          'message':'Template Follow Up 1',
                          'action':'email,wa'
                      },
                      { 
                          'id':'f2',
                          'name':'Kedua',
                          'message':'Template Follow Up 2',
                          'action':'email,wa'
                      },
                      { 
                          'id':'f3',
                          'name':'Ketiga',
                          'message':'Template Follow Up 3',
                          'action':'email,wa'
                      },
                      { 
                          'id':'f4',
                          'name':'Keempat',
                          'message':'Template Follow Up 4',
                          'action':'email,wa'
                      }
                  ],
                  'status': [
                      {
                          'id':'new',
                          'name':'Form Baru',
                          'message':'',
                          'action':'step1,completed,cancelled'
                      },
                      {
                          'id':'step1',
                          'name':'Sudah Dicek',
                          'message':'',
                          'action':'step2,completed,cancelled'
                      },
                      {
                          'id':'step2',
                          'name':'Sudah Dicek Dua Kali',
                          'message':'',
                          'action':'step3,completed,cancelled'
                      },
                      {
                          'id':'step3',
                          'name':'Siap Dikirim',
                          'message':'',
                          'action':'completed,cancelled'
                      },
                      {
                          'id':'completed',
                          'name':'Form Selesai',
                          'message':'',
                          'action':''
                      },
                      {
                          'id':'cancelled',
                          'name':'Form Batal',
                          'message':'',
                          'action':''
                      }
                  ]
              };
            }
          }
        }

        //Set all necessary Setup Variables
        for( let setup of this.setups ){
          if( setup.name == "Sold Out System" ){
            this.has_sold_out = true;
          }else if( setup.name == "Item Management System" ){
            this.has_item = true;
          }else if( setup.name == "Member Management System" ){
            this.has_member = true;
          }else if( setup.name == "PO System" ){
            this.has_po = true;
          }else if( setup.name == "Marketplace System" ){
            this.has_marketplace = true;
            this.marketplace_member_category_id = setup.value;
          }
        }
      }
      
      
    }));
  }

  ClearCache(): Observable<result_modified>{
    let params = new HttpParams();
    console.log( environment.api_url + "/global/clear" );
    return this.http.get<result_modified>( environment.api_url + "/global/clear", 
      { params: params } 
    ).pipe( tap( data => {

    }));
  }

  /**
   * Global JSON
   */
  public form_setup: invoice_form_setup;
  public invoice_setup: invoice_form_setup;

  /**
   * All of the accessible Global Variables ( once used, we will add it here )
   */
  public has_sold_out: boolean = false;
  public has_item: boolean = false;
  public has_member: boolean = false;
  public has_po: boolean = false;
  public is_vendor: boolean = false;
  public has_marketplace: boolean = false;
  public marketplace_member_category_id: string = "";
  

}
