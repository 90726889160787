<ion-toolbar>
  <ion-avatar>
    <img [src]="member_image" class="member_image" />
  </ion-avatar>
  <ion-title>
    <div *ngIf="auth_service.member_pembeli">Akun Satdik</div>
    <div *ngIf="auth_service.member_pembeli == false">Akun Vendor</div>
    {{ member_name }}
    <br>
  </ion-title>
  <ion-button (click)="UploadImage()">
    Upload Image
  </ion-button>
</ion-toolbar>