import { Component, OnInit } from '@angular/core';
import { StaticService } from 'src/app/services/static.service';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {


  header_class: string = ""

  constructor( private shared: StaticService, public authService: AuthenticationService ) { }

  ngOnInit() {
  }

  OpenWhatsapp(){
    this.shared.OpenWhatsapp();
  }

  HideHeader(){
    this.header_class = "hide-header";
  }
  ShowHeader(){
    this.header_class = "";
  }


  //Login Penjual
  login_penjual(){
    this.shared.show_login( "penjual" );
  }

  //Login Penjual
  login_staff(){
    this.shared.show_login( "staff" );
  }


  //Login Pembeli ( Jalur Hijau )
  login_pembeli(){
    this.shared.show_login( "pembeli" );
  }

  //Login DAPODIK
  login_dapodik(){
    this.shared.show_dapodik_login();
  }

}
