import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { 
    path: 'home', 
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'cart', canActivate: [ AuthGuardService ],
    loadChildren: () => import('./cart/cart.module').then( m => m.CartPageModule)
  },
  {
    path: 'history', redirectTo: 'history/all', pathMatch: 'full'
  },
  {
    path: 'history/:type', canActivate: [ AuthGuardService ],
    loadChildren: () => import('./history/history.module').then( m => m.HistoryPageModule)
  },
  {
    path: 'negotiation', redirectTo: 'negotiation/all', pathMatch: 'full'
  },
  {
    path: 'negotiation/:type', canActivate: [ AuthGuardService ],
    loadChildren: () => import('./negotiation/negotiation.module').then( m => m.NegotiationPageModule)
  },
  {
    path: 'account/complaint', redirectTo: 'account/complaint/all', pathMatch: 'full'
  },
  {
    path: 'account/complaint/:type', canActivate: [ AuthGuardService ],
    loadChildren: () => import('./account/complaint/complaint.module').then( m => m.ComplaintPageModule)
  },
  {
    path: 'account/rating', canActivate: [ AuthGuardService ],
    loadChildren: () => import('./account/rating/rating.module').then( m => m.RatingPageModule )
  },
  {
    path: 'account/contact', canActivate: [ AuthGuardService ],
    loadChildren: () => import('./account/contact/contact.module').then( m => m.ContactPageModule )
  },
  {
    path: 'account', canActivate: [ AuthGuardService ],
    loadChildren: () => import('./account/account.module').then( m => m.AccountPageModule )
  },
  {
    path: 'buku', canActivate: [ AuthGuardService ],
    loadChildren: () => import('./list/buku/buku.module').then( m => m.BukuPageModule)
  },
  {
    path: 'produk', canActivate: [ AuthGuardService ],
    loadChildren: () => import('./list/master/master.module').then( m => m.MasterPageModule)
  },
  {
    path: 'item-form', canActivate: [ AuthGuardService ],
    loadChildren: () => import('./list/item-form/item-form.module').then( m => m.ItemFormPageModule)
  },
  {
    path: 'dapodik/:id/:token/:pin/:pengguna',
    loadChildren: () => import('./dapodik/dapodik.module').then( m => m.DapodikPageModule)
  },
  {
    path: 'detail/:page_id',
    loadChildren: () => import('./detail/detail.module').then( m => m.DetailPageModule)
  },
  {
    path: 'multi-cart', canActivate: [ AuthGuardService ], 
    loadChildren: () => import('./multi-cart/multi-cart.module').then( m => m.MultiCartPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
