import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { result_shipping_get, result_shipping_search } from './interfaces.service';

@Injectable({
  providedIn: 'root'
})
export class ShippingService {

  constructor( private http:HttpClient ) { }

  Search( _keyword: string ): Observable<result_shipping_search>{
    /*
    for (var key in _filters) {
      params = params.set( key, _filters[key] );
    }
    */
    return this.http.get<result_shipping_search>( environment.api_url + "/shipping/search/" + _keyword + "/json"
    );
  }

  Get( _origin_code: string, _provinsi: string, _kabupaten: string, _kecamatan: string, _kelurahan: string): Observable< result_shipping_get >{
    let data = {
      data: {
        origin_code: _origin_code,
        provinsi: _provinsi,
        kabupaten: _kabupaten,
        kecamatan: _kecamatan,
        kelurahan: _kelurahan
      }
    };
    return this.http.post< result_shipping_get >( environment.api_url + "/shipping", data );
  }
}
