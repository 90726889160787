import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor( 
    public authentication: AuthenticationService,
    private router: Router,
    private global_service: GlobalService
  ) { }

  async canActivate(): Promise<boolean>{
    //Disable Login Requirement 
    if( this.authentication.initial_loaded == false ){
      await this.authentication.get_member_storage( true );
    }
    if( this.global_service.is_loaded == false ){
      const t = await this.global_service.Load().toPromise();
    }
    let authenticated = this.authentication.isAuthenticate();
    if( authenticated == false ){
      this.router.navigate(['/home']);
    }
    return authenticated;
    //return this.authentication.isAuthenticate();
  }

  
}
