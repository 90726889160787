import { Injectable, ɵɵsetComponentScope } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Router } from '@angular/router';

import { Platform, ToastController, AlertController, ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { environment } from 'src/environments/environment';
import { result_login } from './interfaces.service';
import { HttpClient } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import { ShowLoginComponent } from '../components/show-login/show-login.component';
import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  
  authState = new BehaviorSubject(false);

  constructor(
    private router: Router,
    private storage: Storage,
    private platform: Platform,
    public toastCtrl: ToastController,
    public http: HttpClient,
    private alertCtrl:AlertController,
    private modalCtrl: ModalController,
    private global_service: GlobalService
  ) { 
    this.platform.ready().then(() => {
      
      this.ifLoggedIn();
    })
  }

  //Member Load Account Page
  member_load() {
    return this.http.get<any>( environment.api_url + "/member" ).pipe(
      tap( resData => {
        if( resData && resData.status && resData.status == "ok" ){
          
        }else{
          //Show a popup and then remove
        }
      }),
      catchError(this.handleError('MEMBER EDIT PIPE ERROR', []))
    );
  }


  //Use this to retrieve all of the necessary member_information stored on our system
  //This is called on our Constructor and also on our sign_in method
  //If initial_loaded == false, then our CanActivate must always get_member_storage and wait for it to finish!
  public initial_loaded: boolean = false;
  async get_member_storage( _refresh: boolean = true ): Promise<void>{
    await this.storage.get( "account_token" ).then((val) => {
      if( val && val.length > 0 ){
        this._account_token = val;
      }
    });
    await this.storage.get( "member_id" ).then((val) => {
      if( val && val.length > 0 ){
        this._member_id = val;
      }
    });
    await this.storage.get( "pengguna_token" ).then((val) => {
      if( val && val.length > 0 ){
        this._pengguna_token = val;
      }
    });
    await this.storage.get( "pengguna_id" ).then((val) => {
      if( val && val.length > 0 ){
        this._pengguna_id = val;
      }
    });
    await this.storage.get( "pengguna_name" ).then((val) => {
      if( val && val.length > 0 ){
        this._pengguna_name = val;
      }
    });
    await this.storage.get( "type" ).then((val) => {
      if( val && val.length > 0 ){
        this._type = val;
      }
    });
    await this.storage.get( "email" ).then((val) => {
      if( val && val.length > 0 ){
        this.email = val;
      }
    });
    await this.storage.get( "zone" ).then((val) => {
      if( val && val > 0 ){
        this.zone = val;
      }
    });

    await this.storage.get( "member_name" ).then((val) => {
      if( val && val.length > 0 ){
        this._member_name = val;
      }
    });
    await this.storage.get( "mobile_number" ).then((val) => {
      if( val && val.length > 0 ){
        this.mobile_number = val;
      }
    });
    await this.storage.get( "gender" ).then((val) => {
      if( val && val.length > 0 ){
        this.gender = val;
      }
    });
    await this.storage.get( "date_of_birth" ).then((val) => {
      if( val && val.length > 0 ){
        this.date_of_birth = val;
      }
    });
    await this.storage.get( "info_1" ).then((val) => {
      if( val && val.length > 0 ){
        this.info_1 = val;
      }
    });
    await this.storage.get( "page_category_id" ).then((val) => {
      if( val && val.length > 0 ){
        this._page_category_id = val;
      }
    });
    this.initial_loaded = true;
    if( _refresh ){
      this.logged_in();
    }
  }
  //This is the SET Counterparts
  async set_member_storage( resData: result_login, _refresh: boolean = true ): Promise<void>{
    await this.storage.set( "account_token", resData.data.account_token );
    await this.storage.set( "email", resData.data.email );
    await this.storage.set( "member_id", resData.data.member_id );
    await this.storage.set( "member_name", resData.data.name );
    await this.storage.set( "mobile_number", resData.data.mobile_number );
    await this.storage.set( "gender", resData.data.gender );
    await this.storage.set( "date_of_birth", resData.data.date_of_birth );
    await this.storage.set( "info_1", resData.data.info_1 );
    await this.storage.set( "zone", resData.data.zone );
    await this.storage.set( "page_category_id", resData.data.page_category_id );
    await this.storage.set( "type", resData.data.type );

    if( resData.data.type == "dapodik" ){
      await this.storage.set( "pengguna_id", resData.data.additional_data.pengguna_id );
      await this.storage.set( "pengguna_token", resData.data.additional_data.account_token );
      await this.storage.set( "pengguna_name", resData.data.additional_data.nama );
    }else{
      await this.storage.set( "pengguna_id", "" );
      await this.storage.set( "pengguna_token", "" );
      await this.storage.set( "pengguna_name", "" );
    }
    this.get_member_storage( _refresh );
  }

  ifLoggedIn(){
    this.get_member_storage();
  }

  dapodik( data ): Observable<any>{
    return this.http.post<result_login>(environment.external_url  + '/endpoint/login', data ).pipe(
      tap( resData => {
        if( resData && resData.status && resData.status == "ok" ){
          if( resData.data.verified == 0 ){
            //Display Verification Window
            this.alertCtrl.create({
              header: "Login Error",
              message: "Member belum diverifikasi",
              buttons: ['Dismiss']
            }).then( alertEl => {
              alertEl.present();
            }); 
          }else if( resData.data.account_token.length > 0 && resData.data.verified == 1 ){
            this.set_member_storage( resData );
          }
        }else{
          //this.logged_out();
          //20210517 :: We will display error, and give 2 buttons , home or try again
        }
      }), 
      catchError(this.handleError('LOGIN PIPE ERROR', []))
    );
  }

  login( data ): Observable<any> {
    this._forced_logout_happened = false;
    let api_url: string = environment.api_url;
    if( data.type == "staff" ){
      api_url += "/staff/signin";
    }else{
      api_url += "/member/signin";
    }
    return this.http.post<result_login>( api_url, data ).pipe(
      tap( resData => {
        if( resData && resData.status && resData.status == "ok" ){
          if( resData.data.verified == 0 ){
            //Display Verification Window
            this.alertCtrl.create({
              header: "Login Error",
              message: "Member belum diverifikasi",
              buttons: ['Dismiss']
            }).then( alertEl => {
              alertEl.present();
            }); 
          }else if( resData.data.page_category_id == "3347_39" ){
            //Display Verification Window
            this.alertCtrl.create({
              header: "Login Error",
              message: "Akun Vendor belum diaktifasi",
              buttons: ['Dismiss']
            }).then( alertEl => {
              alertEl.present();
            }); 
          }else if( resData.data.account_token.length > 0 && resData.data.verified == 1 ){
            this.set_member_storage( resData ).then( _ => this.get_member_storage() );
          }
        }else{
          this.logged_out();
        }
      }), 
      catchError(this.handleError('LOGIN PIPE ERROR', []))
    );
  }
  
  logged_in(){
    if( this.account_token != "" && this.member_id != "" && this.type != "" ){
      this._user_signed_in = true;
      this.authState.next(true);
    }
  }

  //This flag is resetted at login attempt
  private _forced_logout_happened: boolean = false;
  public get forced_logout_happened():boolean{
    return this._forced_logout_happened;
  }
  logout( _forced: boolean = false ){
    //20210617 :: Forced Logout can only ever happened once
    if( _forced && this.forced_logout_happened ){
      console.log( "STOP FORCED LOGOUT TERJADI, krn sudah pernah terjadi sekali!" );
      return false;
    }else if( _forced ){
      console.log( "FORCED LOGOUT TERJADI, catat tapi next time udah kaga boleh!" );
      this._forced_logout_happened = _forced ;
    }
    //Execute SIGN OUT
    if( this.account_token != "" && this.account_token.length > 0 ){
      let api_url: string = environment.api_url;
      if( false ){
        api_url += "/staff/signout";
      }else{
        api_url += "/member/signout";
      }
      let data = [];
      this.http.post<result_login>( api_url, data ).subscribe( _data => {
        console.log("Logout Successful", _data );
      });
    }
    
    this.logged_out();
  }

  public logged_out(){
    this.set_member_storage( {status: "", status_message:"", elapsed_time: "0", data:{ account_token:"", member_id:"", name:"", zone:0 } }  ).then( _ => {
      this.get_member_storage().then( _ => {
        this._user_signed_in = false;
        this.router.navigateByUrl( "/home" );
        this.authState.next(false);
      });
    });
  }

  isAuthenticate(){
    return this.authState.value;
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a HeroService message with the MessageService */
  private log(message: string) {
    console.log(message);
  }


  //Network Authentication Variables
  private _user_signed_in = false;
  get user_signed_in(){
    return this._user_signed_in;
  }
  private _account_token = "";
  private _member_id = "";
  private _page_category_id = "";
  private _member_name = "";
  
  public info_1 = "";
  public email = "";
  public gender = "";
  public mobile_number = "";
  public date_of_birth = "";
  public zone = 0;
  get account_token(){
    return this._account_token;
  }
  get member_id(){
    return this._member_id;
  }
  get page_category_id(){
    return this._page_category_id;
  }
  get member_name(){
    return this._member_name;
  }
  get member_pembeli(){
    if( this.user_signed_in == false || ( this.page_category_id != null && ( this.page_category_id == "3347_39" || this.page_category_id == "3347_40" ) ) ){
      return false;
    }
    return true;
  }

  //20210516 :: New Pengguna ID and TOKEN
  private _type = "";
  private _pengguna_id = "";
  private _pengguna_token = "";
  private _pengguna_name = "";
  get pengguna_id(){
    return this._pengguna_id;
  }
  get pengguna_token(){
    return this._pengguna_token;
  }
  get pengguna_name(){
    return this._pengguna_name;
  }
  get type(){
    return this._type;
  }

}
