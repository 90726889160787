import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { file_image, result_file_image_get, result_modified } from './interfaces.service';
import { Observable } from 'rxjs';

export interface file_parent {
  cms_type: string;
  edit_type: string;
  primary_id: string;
  file_data_id?: string;
  upload_token?: string; //this is setted to override the account_token after user registere
}

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor( private http: HttpClient ) { }

  Load( file_data: file_image ) {
    //return this.http.get<file_image[]>( this.get_api_url( file_data ) );
  }
  
  //20210613 :: upload_type is used on our member to mark which image is ProfilePicture, NIK, NPWP, SIUP, TDP
  //20210614 :: upload_token is given after registration is done, we can use the token to upload file
  uploadImage( _target_parent:file_parent, target_file: file_image ):Observable<result_file_image_get> {
    const httpOptions = {
      headers: new HttpHeaders({
        'enctype': 'multipart/form-data;'
      })
    };
    const formData = new FormData();
    target_file.uploading = true;
    formData.append("Filedata", target_file.file_blob, target_file.file_name );
    formData.append("force_jpg", target_file.force_jpg );
    if( target_file.upload_type && target_file.upload_type.length > 0 ){
      formData.append("upload_type", target_file.upload_type );
    }
    if( _target_parent.upload_token && _target_parent.upload_token != "" ){
      formData.append("upload_token", _target_parent.upload_token );
      formData.append("primary_id", _target_parent.primary_id );
    }
    console.log( _target_parent );
    console.log( target_file );
    console.log( formData.get("upload_token") );
    return this.http.post<result_file_image_get>(  this.get_api_url( _target_parent ), formData, httpOptions);
  }
 
  deleteImage( _target_parent:file_parent, _file_data_id: string ):Observable<result_modified> {
    _target_parent.file_data_id = _file_data_id;
    return this.http.delete<result_modified>( this.get_api_url( _target_parent ) );
  }
  Edit( _target_parent: file_parent, _file_data_id: string, _data ):Observable<result_modified>{
    _target_parent.file_data_id = _file_data_id;
    return this.http.put<result_modified>( this.get_api_url( _target_parent ), _data );
  }

  get_api_url( file_data: file_parent ): string{
    let api_url = environment.api_url;
    api_url += "/" + file_data.cms_type;
    api_url += "/" + file_data.edit_type;
    api_url += "/" + file_data.primary_id;
    api_url += "/image";
    if( typeof file_data.file_data_id !== "undefined" && file_data.file_data_id != null ){
      api_url += "/" + file_data.file_data_id;
    }
    console.log( api_url );
    return api_url;
  }

}
