import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicStorageModule } from '@ionic/storage';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { AuthGuardService } from './services/auth-guard.service';
import { AuthenticationService } from './services/authentication.service';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorService } from './services/interceptor.service';
import { ComponentsModule } from './components/components.module';
import { ShowLoginComponent } from './components/show-login/show-login.component';
import { FormsModule } from '@angular/forms';
import { RegisterComponent } from './login/register/register.component';
import { ResetComponent } from './login/reset/reset.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { VerifyComponent } from './login/verify/verify.component';
import { registerLocaleData } from '@angular/common';
import localeId from '@angular/common/locales/id';
import { IonicSelectableModule } from 'ionic-selectable';
registerLocaleData( localeId );
@NgModule({
  declarations: [ AppComponent, ShowLoginComponent, RegisterComponent, ResetComponent, VerifyComponent ],
  entryComponents: [ ShowLoginComponent, RegisterComponent, ResetComponent, VerifyComponent ],
  imports: [
    BrowserModule, 
    IonicModule.forRoot( { mode: "md" } ), 
    IonicStorageModule.forRoot(), 
    AppRoutingModule,
    HttpClientModule, ComponentsModule, FormsModule, ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    IonicSelectableModule
  ],
  providers: [
    StatusBar,
    SplashScreen, 
    AuthGuardService, AuthenticationService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    { provide: LOCALE_ID, useValue: 'id'},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
