import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ToastController, AlertController } from '@ionic/angular';
import { AuthenticationService } from './authentication.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
  
  constructor(
    private auth_service: AuthenticationService,
    public toastController: ToastController,
    private alertCtrl: AlertController) {
    }
 
  // Intercepts all HTTP requests!
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if ( this.auth_service.account_token != "" && this.auth_service.member_id != "" ) {
      if( this.auth_service.type == "dapodik" ){
        request = request.clone({
          setHeaders: {
            'Authorization': "dapodik|" + this.auth_service.member_id+ "|" + this.auth_service.account_token + 
            "|" + this.auth_service.pengguna_id+ "|" + this.auth_service.pengguna_token
          }
        });
      }else{
        request = request.clone({
          setHeaders: {
            'Authorization': "member|" + this.auth_service.member_id+ "|" + this.auth_service.account_token
          }
        });
      }
    }else if( request.body && typeof request.body.get == "function" && request.body.get("upload_token") && request.body.get("primary_id") ){
      //20210614 :: Special for uploading registered member
      request = request.clone({
        setHeaders: {
          'Authorization': "member|" + request.body.get("primary_id") +  "|" + request.body.get("upload_token")
        }
      });
    }else{
      request = request.clone({
        setHeaders: {
          'Authorization': "ikt.co.id|" + environment.token_key
        }
      });
    }
    if( request.url.indexOf("csv") > 0 ){
      if (!request.headers.has('Content-Type') && !request.headers.has('enctype')) {
        request = request.clone({
          setHeaders: {
            'content-type': 'text/csv'
          }
        });
      }
      request = request.clone({
        headers: request.headers.set('Accept', 'text/csv')
      });

      console.log( request );
    }else{
      if (!request.headers.has('Content-Type') && !request.headers.has('enctype')) {
        request = request.clone({
          setHeaders: {
            'content-type': 'application/json'
          }
        });
      }
      request = request.clone({
        headers: request.headers.set('Accept', 'application/json')
      });
    }

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if( event.body.status == "error" && 
            ( event.body.status_message.indexOf("Login First") >= 0 ||
              event.body.status_message.indexOf("Access Denied") >= 0 || 
              event.body.status_message.indexOf("Token Expired") >= 0) ){
              if( this.auth_service.user_signed_in ){
                //Show Alert
                if( this.auth_service.forced_logout_happened == false ){
                  this.alertCtrl.create( { 
                    header: "Login Failure",
                    message: event.body.status_message,
                    buttons: ['Close', {
                      text:'Hubungi Kami ( WA )', handler: () =>{
                        this.OpenWhatsapp();
                      }
                    }]
                  }).then( alertEl =>{
                    alertEl.present();
                  })
                }
                
                //Force Logout
                this.auth_service.logout( true );
              }
          }
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        console.log( "Interceptor Error", error );
        if (error.status === 401 || error.status >= 500) {
          if( this.auth_service.forced_logout_happened == false ){
            this.presentToast( error.status + " : " + error.message );
            this.auth_service.logout( true );
          }
        }
        return throwError(error);
      }));
  }

  async presentToast(msg: string) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
      position: 'top'
    });
    toast.present();
  }

  OpenWhatsapp(){
    window.open( environment.whatsapp, "_system", "location=yes");
  }
}
