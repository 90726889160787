<ion-header>
  <ion-toolbar>
    <ion-title>Reset Password Preparation</ion-title>
    <ion-buttons slot="primary" color="primary">
      <ion-button (click)="on_close()">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding" >
  <ion-text>Masukan email anda untuk memulai proses perubahan password</ion-text>
  <form (ngSubmit)="on_submit()">
    <ion-grid>
      <ion-row>
        <ion-col size-sm="6" offset-sm="3">
          <ion-item>
            <ion-label position="floating">Email</ion-label>
            <ion-input required type="email" [(ngModel)]="form_data.email" name="email" (keyup.enter)="on_submit()" ></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size-sm="6" offset-sm="3">
          <ion-button type="submit" expand="block" color="primary">Reset Password</ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
</ion-content>