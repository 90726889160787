/**
 * Every Single General Functions Goes Here
 */
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AlertController, ModalController } from '@ionic/angular';
import { ShowLoginComponent } from '../components/show-login/show-login.component';
import { filter_by, group, item } from './interfaces.service';
import { Subject, Subscription } from 'rxjs';
import { Storage } from '@ionic/storage';
import { InAppBrowser } from '@ionic-native/in-app-browser';
import { Router } from '@angular/router';

declare let window: any; 

//Static Variables is used at our runtime , to store necessary information ( this information will also be stored at our storage? )
export class StaticVariables {
  public cms_type: string = "item";
  public filters_blog: filter_by[] = [];
  public filters_item: filter_by[] = [];
  public filters_master: filter_by[] = [];
  public filters_member: filter_by[] = [];
  public filters_transaction: filter_by[] = [];
  public quick_edit_blog: string[] = [];
  public quick_edit_item: string[] = [];
  public quick_edit_member: string[] = [];
  public quick_edit_master: string[] = [];
  constructor( obj = null) {
    if( obj != null ){
      for( var prop in obj ){
        if( this.hasOwnProperty( prop ) ) {
          this[prop] = obj[prop];
        }
      }
    }
  }

  get filters(): filter_by[]{
    return this["filters_" + this.cms_type];
  }
  set filters( _filter_by: filter_by[] ){
    this["filters_" + this.cms_type ] = _filter_by;
  }

  get quick_edit(): string[]{
    return this[ "quick_edit_" + this.cms_type ];
  }
  set quick_edit( _quick_edit: string[] ){
    this[ "quick_edit_" + this.cms_type ] = _quick_edit;
  }

}
export const static_variables = new StaticVariables();


@Injectable({
  providedIn: 'root'
})
export class StaticService {

  public thumbnail_style_id_medium: string = "598_27";

  //20210521 :: Menu Primary / Menu Account 
  public get menu_type():string{
    if( 
      this.router.url.indexOf("/account") === 0 || this.router.url.indexOf("/history") === 0 || 
      this.router.url.indexOf("/negotiation") === 0 || this.router.url.indexOf("/produk") === 0 || this.router.url.indexOf("/buku") === 0
    ){
      return "account";
    }
    return "primary";
  };

  //Shared Master / Member Registration , we must be able to unsubscribe before subscribing back again
  public master_router_subscription: Subscription;

  constructor(
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private storage: Storage, private router: Router
  ) { 
    console.log( "STATIC SERVICE INITTED" );
    this.load_all();
  }


  /**
   * @method OpenWhatsApp to contact our business whatsapp
   */
  OpenWhatsapp(){
    this.OpenURL( environment.whatsapp );
  }

  OpenURL( _url: string ){
    window.open( _url, "_system", "location=yes");
  }

  /**
   * @method ShowAlert 
   * Can be used to show dummy alert error message , or show a smart Alert with buttons and behavior
   */
  async ShowAlert( _header: string, _message: string, _inputs, _buttons ){
    const alert = await this.alertCtrl.create({
      header: _header,
      message: _message,
      inputs: _inputs,
      buttons: _buttons
    })
    await alert.present();
  }

  
  /**
   * @method show_window_login
   * This is called when visitor clicked the "Login Penjual / Jalur Hijau" button at the header and Menu Slider
   * Dapodik Login will use show_dapodik_login instead
   * @param is_pembeli
   * @returns void
   */
  public show_login( _type = "penjual" ){
    console.log( "Show Login", _type );
    this.modalCtrl.create({
      component: ShowLoginComponent,
      componentProps: { type: _type },
      cssClass: "login-modal-" + _type,
      id: "LoginModal"
    }).then( modalEl => {
      modalEl.present();
      return modalEl.onDidDismiss();
    }).then( resultData => {
      console.log( resultData );
    })
  }

  /**
   * @method show_dapodik_login
   * There is no other way , we must redirect to dapodik screen and return back to our web :|
   * 
   * @returns void
   */
  
  async show_dapodik_login(){
    let dapodik: string = "http://dev-sso.datadik.kemdikbud.go.id/app/" + environment.app_id;
    //dapodik = "https://www.ikt.co.id/ClearOpCache.php";
    let ref = InAppBrowser.create( dapodik, "_self", "location=no");
    if( window.cordova ){
      ref.on('loadstart').subscribe(event => {
        console.log( event );
      });
    }

    /*
    let browser = InAppBrowser.create('https://www.ikt.co.id/ClearOpCache.php');
    browser.on('loadstop').subscribe(event => {
      console.log( "Browser Stop", event );
    });
    */
    /*
    let browser = this.iab.create('https://ionicframework.com/');
    browser.on('loadstop').subscribe(event => {
      console.log( "Browser Stop", event );
    });
    */
  }


  /**
   * @method SortArray
   * Sort an array by comparing the field inside 
   * array.sort( sort_array("ordinal", true) ) 
   */
  public sort_array( property: string, is_asc: boolean ){
    var sortOrder = 1;
    if( is_asc == false ) {
        sortOrder = -1;
    }
    return function (a,b) {
        /* next line works with strings and numbers, 
         * and you may want to customize it to your needs
         */
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
  }

  /**
   * @method clone_object
   * We first use this to clone our page when we are adding multiple pages at the same time ( we might need to use this again )
   */
  public clone_object(source, deep) {
    var o, prop, type;

    if (typeof source != 'object' || source === null) {
      // What do to with functions, throw an error?
      o = source;
      return o;
    }

    o = new source.constructor();

    for (prop in source) {

      if (source.hasOwnProperty(prop)) {
        type = typeof source[prop];

        if (deep && type == 'object' && source[prop] !== null) {
          o[prop] = this.clone_object(source[prop], deep);
        } else {
          o[prop] = source[prop];
        }
      }
    }
    return o;
  }


  /**
   * @method add_filter
   * @method remove_filter
   * @method get_filter
   * We use this massively on Master Component and ItemForm Component
   */
  public filter_change_listener: Function = null;
  private filter_subject = new Subject<filter_by[]>();
  public get_observable_filter(): Subject<filter_by[]>{
    return this.filter_subject;
  }
  add_filter( _value: filter_by ){
    if( _value.type == "group" ){
      if( _value.id == null ){
        //Remove All Group
        //static_variables["filters_" + static_variables.cms_type] = static_variables["filters_" + static_variables.cms_type].filter( function( value, index, arr ){ return value.type != _value.type; });
        static_variables.filters = static_variables.filters.filter( function( value, index, arr ){ return value.type != _value.type; });
      }else{
        //Always Remove all Group of the same PID and GROUP, we will never ever have something ever exists!
        //static_variables["filters_" + static_variables.cms_type] = static_variables["filters_" + static_variables.cms_type].filter( function( value, index, arr ){ return value.pid != _value.pid || value.type != _value.type; });
        static_variables.filters = static_variables.filters.filter( function( value, index, arr ){ return value.pid != _value.pid || value.type != _value.type; });
      }
    }else if( 
      _value.type == "category" || 
      _value.type == "keyword" || 
      _value.type == "sort" || _value.type == "is_draft" || _value.type == "price" /* ||
      _value.type.indexOf("_min") > -1 || 
      _value.type.indexOf("_max") > -1 */ ){
      //Always Remove all Category
      //static_variables["filters_" + static_variables.cms_type] = static_variables["filters_" + static_variables.cms_type].filter( function( value, index, arr ){ return value.type != _value.type; });
      static_variables.filters = static_variables.filters.filter( function( value, index, arr ){ return value.type != _value.type; });
    }else if( _value.type == "tag" ){
      //Remove the Tag ( if it is a duplicate )
      static_variables.filters = static_variables.filters.filter( function( value, index, arr ){ return value.id != _value.id || value.type != _value.type; });
    }
    if( _value.id != null ){
      if( _value.type == "price" ){
        //Never add if the id.lower and id.upper is equal
        if( _value.id.lower < _value.id.upper ){
          static_variables.filters.unshift( _value );
        }
      }else{
        static_variables.filters.unshift( _value );
      }
      
    }
    this.save_all();
    this.filter_subject.next( static_variables.filters );
    return static_variables.filters;
  }
  remove_filter( _value: filter_by ){
    for( let i = 0 ; i < static_variables.filters.length ; i++ ){
      let filter = static_variables.filters[i];
      if( filter == _value || ( (_value.type == "category" || _value.type == "keyword") && _value.type == filter.type ) ){
        static_variables.filters.splice( i, 1 );
        break;
      }
    }
    this.save_all();
    this.filter_subject.next( static_variables.filters );
    return static_variables.filters;
  }
  get_filter(): filter_by[]{
    return static_variables.filters;
  }
  //first used to retrieve the TYPE Sort on our filter
  get_filter_id( _type: string ): string{
    let id: string = "";
    for( let filter of static_variables.filters ){
      if( filter.type == _type ){
        id = filter.id;
      }
    }
    return id;
  }

  get_quick_edit(): string[]{
    if( typeof static_variables.quick_edit != "undefined" ){
      return static_variables.quick_edit;
    }
    return null;
  }
  set_quick_edit( _quick_edit: string[] ){
    static_variables.quick_edit = _quick_edit;
    this.save_all();
  }

  async save_all(){
    await this.storage.set( "filters_blog", JSON.stringify( static_variables.filters_blog ) );
    await this.storage.set( "filters_item", JSON.stringify( static_variables.filters_item ) );
    await this.storage.set( "filters_member", JSON.stringify( static_variables.filters_member ) );
    await this.storage.set( "filters_master", JSON.stringify( static_variables.filters_master ) );
    await this.storage.set( "quick_edit_blog", JSON.stringify( static_variables.quick_edit_blog ) );
    await this.storage.set( "quick_edit_item", JSON.stringify( static_variables.quick_edit_item ) );
    await this.storage.set( "quick_edit_member", JSON.stringify( static_variables.quick_edit_member ) );
    await this.storage.set( "quick_edit_master", JSON.stringify( static_variables.quick_edit_master ) );
  }

  async load_all(){
    await this.storage.get( "filters_blog" ).then((val) => {
      if( typeof val != "undefined" && val != null){
        static_variables.filters_blog = JSON.parse( val );
      }
    });
    await this.storage.get( "filters_item" ).then((val) => {
      if( typeof val != "undefined"  && val != null ){
        static_variables.filters_item = JSON.parse( val );
      }
    });
    await this.storage.get( "filters_member" ).then((val) => {
      if( typeof val != "undefined"  && val != null ){
        static_variables.filters_member = JSON.parse( val );
      }
    });
    await this.storage.get( "filters_master" ).then((val) => {
      if( typeof val != "undefined"  && val != null ){
        static_variables.filters_master = JSON.parse( val );
      }
    });
    await this.storage.get( "quick_edit_blog" ).then((val) => {
      if( typeof val != "undefined" && val != null){
        static_variables.quick_edit_blog = JSON.parse( val );
      }
    });
    await this.storage.get( "quick_edit_item" ).then((val) => {
      if( typeof val != "undefined"  && val != null ){
        static_variables.quick_edit_item = JSON.parse( val );
      }
    });
    await this.storage.get( "quick_edit_member" ).then((val) => {
      if( typeof val != "undefined"  && val != null ){
        static_variables.quick_edit_member = JSON.parse( val );
      }
    });
    await this.storage.get( "quick_edit_master" ).then((val) => {
      if( typeof val != "undefined"  && val != null ){
        static_variables.quick_edit_master = JSON.parse( val );
      }
    });
  }

  //20210608 :: Capability to Search by price_min and price_max
  public price_min: number = 0;
  public price_max: number = 0;
  public formatted_price_min: string = "";
  public formatted_price_max: string = "";
  public price_step: number = 20;

  //20210121 :: IKT Get Cookie, we first use this to get the domain
  public get_cookie( _name: string ):string {
    var name = _name + '=';
    var cookie_arr = document.cookie.split(';');
    for( var i = 0 ; i < cookie_arr.length ; i++ ) {
      var cookie = cookie_arr[i];
      while (cookie.charAt(0)==' ') cookie = cookie.substring(1);
      if (cookie.indexOf(name) == 0) return cookie.substring(name.length,cookie.length);
    }
    return '';
  }

  //20210604 :: Convert UNIX TIME to Date format
  private date_time_options: any = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  public get_unix_date( _unix: number ): string{
    var formatted_date = "";
    var date = new Date( _unix );
    var local_time = date.toLocaleTimeString("id-ID", this.date_time_options);
    formatted_date = local_time;
    return formatted_date;
  }


  //20210614 :: Keep the ITEM Detail here, so that we no longer need to load for our /detail/ID
  public current_detail: item;


}
