import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Router } from '@angular/router';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { StaticService } from 'src/app/services/static.service';
import { NgForm } from '@angular/forms';
import { ResetComponent } from 'src/app/login/reset/reset.component';
import { RegisterComponent } from 'src/app/login/register/register.component';

@Component({
  selector: 'app-show-login',
  templateUrl: './show-login.component.html',
  styleUrls: ['./show-login.component.scss'],
})
export class ShowLoginComponent implements OnInit {

  type = "penjual";
  isLoading = false;
  isLogin = true;
  authentication = { username: "", password: "" }

  constructor(
    private router: Router,
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController,
    private modalCtrl: ModalController, private authService: AuthenticationService
  ) { }
  public get is_pembeli(): boolean{
    return this.type == "pembeli";
  }
  ngOnInit() {
    //return true;
    if( this.type == "pembeli" ){
      this.authentication.username = "pembeli@siplahmasmedia.co.id";
      this.authentication.password = "Pembeli123!@#";
    }else{
      this.authentication.username = "penyedia1@siplahmasmedia.co.id";
      this.authentication.password = "Penyedia123!@#";
    }
  }

  
  onSwitchAuth(){
    this.isLogin = !this.isLogin;
  }
  onLogin( _formEl: NgForm ){
    this.isLoading = true;
    /*
    var username = _formEl.form.value.username;
    var password = _formEl.form.value.password;
    */
    this.loadingCtrl
    .create({ keyboardClose: true, message: 'Signing in...' })
    .then(loadingEl => {
      loadingEl.present();
      this.authService.login( { username: this.authentication.username, password: this.authentication.password, type: this.type } ).subscribe( resData=>{
        console.log ( resData );
        console.log( resData.status );
        this.isLoading = false;
        loadingEl.dismiss();
        if( resData.status == "error" ){
          const alert = this.alertCtrl.create({
            header: "Tidak Bisa Login",
            message: resData.status_message,
            inputs: [],
            buttons: [
              {
                text: "Close",
                role: "cancel"
              }
            ]
          }).then( alertEl => {
            alertEl.present();
          });
        }else{
          this.on_close();
        }
        
      }, errData=> {
        this.isLoading = false;
        loadingEl.dismiss();
      });
    });
  }

  onReset(){
    //Open new Reset Password modal
    this.modalCtrl.create( {
      component: ResetComponent,
      componentProps: {},
      id: "ResetModal"} 
    ).then( modalEl =>{
      modalEl.present();
    });
  }

  onRegister(){
    //Open new Register New Account modal
    this.modalCtrl.create( {component: RegisterComponent,
      componentProps: { is_pembeli: this.type == "pembeli" },
      id: "RegisterModal"} 
    ).then( modalEl =>{
      modalEl.present();
    });
  }

  on_close(){
    this.modalCtrl.dismiss( null, "cancel", "LoginModal" );
  }

  presentAlert( error_message ) {
    let alert = this.alertCtrl.create({
      header: 'Error',
      message: error_message,
      buttons: ['Dismiss']
    }).then( alertEl => {
      alertEl.present();
    });
    
  }


}
