import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';
import { AuthenticationService } from './services/authentication.service';
import { StaticService } from './services/static.service';
import { GlobalService } from './services/global.service';
import { formatCurrency } from '@angular/common';
import { filter_by } from './services/interfaces.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {


  public get transaction_opened(): boolean{
    if( this.router.url.indexOf("/history") == 0 ){
      return true;
    }
    return false;
  }

  public get negotiation_opened(): boolean{
    if( this.router.url.indexOf("/negotiation") == 0 ){
      return true;
    }
    return false;
  }

  public get complaint_opened(): boolean{
    if( this.router.url.indexOf("/account/complaint") == 0 ){
      return true;
    }
    return false;
  }

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    public authentication: AuthenticationService,
    public static_service: StaticService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      /*
      this.authentication.authState.subscribe(state => {
        console.log( state );
        if (state) {
          this.router.navigate(['home']);
        } else {
          this.router.navigate(['home']);
        }
      });
      */
    });
  }

  //20210608 :: Price Ranges Filter
  price_range: any = { lower: 0, upper: 0 };
  formatted_price_min: string = "";
  formatted_price_max: string = "";
  on_price_range_change(){
    this.formatted_price_min = formatCurrency( this.price_range.lower, "id", "Rp ", "IDR");
    this.formatted_price_max = formatCurrency( this.price_range.upper, "id", "Rp ", "IDR");
    let price_filter: filter_by = { 
      id: this.price_range, 
      pname: "Price Range", 
      name: this.formatted_price_min + " - " + this.formatted_price_max, 
      type: "price" 
    };
    this.static_service.add_filter( price_filter );
   
  }

  //20210608 :: Rating Filter
  rating: any[] = [
    {id: 1, name:"★" },
    {id: 2, name:"★★" },
    {id: 3, name:"★★★" },
    {id: 4, name:"★★★★" },
    {id: 5, name:"★★★★★" }
  ];
  filter_rating: any;
  on_rating_change(){
    let rating_filter: filter_by = { 
      id: this.filter_rating.id, 
      pname: "Rating", 
      name: this.filter_rating.name, 
      type: "rating" 
    };
    this.static_service.add_filter( rating_filter );
  }
}
