<ion-app>
  <ion-header>
    <app-header #appheader ></app-header>
  </ion-header>
  <ion-split-pane when="lg" contentId="main">
    <ion-menu side="start" contentId="main" menuId="primary" >
      <ion-header>
        <ion-toolbar color="secondary">
          <ion-title>Menu</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list>
          <ion-menu-toggle auto-hide="false" menu="primary" >
            
            <app-member-widget *ngIf="authentication.user_signed_in"></app-member-widget>

            <!-- Primary Menu -->
            <div *ngIf="static_service.menu_type == 'primary'">
              <ion-item   *ngIf="authentication.user_signed_in" lines="none" button routerLink="/account"  routerLinkActive="active-item" >
                <ion-icon name="person" slot="start" color="light"></ion-icon>
                <ion-icon name="chevron-forward-outline" slot="end" color="light"></ion-icon>
                <ion-label>Account Dashboard</ion-label>
              </ion-item>
              <ion-item lines="none" button routerLink="/home"  routerLinkActive="active-item"  >
                <ion-icon name="home" slot="start" color="light"></ion-icon>
                <ion-label>Home</ion-label>
              </ion-item>          
              <ion-item *ngIf="authentication.member_pembeli == true" lines="none" button routerLink="/multi-cart"  routerLinkActive="active-item" >
                <ion-icon name="cart" slot="start" color="light"></ion-icon>
                <ion-label>Cart</ion-label>
              </ion-item>
              <ion-item>
                <ion-label position="floating">Rating Vendor</ion-label>
                <ion-select name="filter_rating" type="string" (ionChange)="on_rating_change()"  [(ngModel)]="filter_rating">
                  <ion-select-option *ngFor="let single_rating of rating" [value]="single_rating.id">{{ single_rating.name }}</ion-select-option>
                </ion-select>
              </ion-item>
              <ion-grid color="">
                <ion-row>
                  <ion-col>

                    
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col>
                    <ion-label color="light">Range {{ static_service.formatted_price_min }} - {{ static_service.formatted_price_max }}</ion-label>
                    <br>
                    <ion-label color="light">Filter {{ formatted_price_min }}</ion-label>
                    <ion-label color="light">- {{ formatted_price_max }}</ion-label>
                  </ion-col>
                </ion-row>
              </ion-grid>
                <ion-range 
                *ngIf="static_service.price_max > 10"
                debounce="500" pin="true" 
                (ionChange)="on_price_range_change()" 
                dualKnobs="true" [(ngModel)]="price_range" 
                [min]="static_service.price_min" 
                [max]="static_service.price_max" 
                [step]="static_service.price_step" 
                snaps="true"
                color="danger">
              </ion-range>
              <ion-item>

              </ion-item>

              <!--
              <ion-item lines="none" button (click)="onLogout()" >
                <ion-icon name="exit" slot="start"></ion-icon>
                <ion-label>Logout</ion-label>
              </ion-item>
              -->
            </div>

            <!-- Account Menu -->
            <div *ngIf="static_service.menu_type == 'account'">
              <!-- Member Profile Picture & Name -->
              
              <ion-item lines="none" button routerLink="/home"  routerLinkActive="active-item"  >
                <ion-icon name="cart" slot="end"  color="light"></ion-icon>
                <ion-icon name="chevron-back-outline" slot="start" color="light"></ion-icon>
                <ion-label>Kembali Belanja</ion-label>
              </ion-item>          
              <ion-item lines="none" button routerLink="/account/dashboard"  routerLinkActive="active-item">
                <ion-icon name="home" slot="start" color="light"></ion-icon>
                <ion-label>Dashboard</ion-label>
              </ion-item>
              <ion-item lines="none" button routerLink="/account/profile"  routerLinkActive="active-item"  >
                <ion-icon name="person" slot="start" color="light"></ion-icon>
                <ion-label>Profile</ion-label>
              </ion-item>
              <ion-item *ngIf="authentication.member_pembeli == false" lines="none" button routerLink="/produk"  routerLinkActive="active-item"  >
                <ion-icon name="list" slot="start" color="light"></ion-icon>
                <ion-label>Daftar Barang / Jasa</ion-label>
              </ion-item>
              <ion-item *ngIf="authentication.member_pembeli == false" lines="none" button routerLink="/buku"  routerLinkActive="active-item"  >
                <ion-icon name="book" slot="start" color="light"></ion-icon>
                <ion-label>Daftar Buku</ion-label>
              </ion-item>
              <ion-item lines="none" button routerLink="/history"  routerLinkActive="active-item" >
                <ion-icon name="basket" slot="start" color="light"></ion-icon>
                <ion-label>Transaction</ion-label>
              </ion-item>
              <div *ngIf="transaction_opened" class="submenu">
                <a lines="none" button routerLink="/history/all"  routerLinkActive="active-item" >
                  <ion-label>Semua Transaksi</ion-label>
                </a>
                <a lines="none" button routerLink="/history/new"  routerLinkActive="active-item" >
                  <ion-label>Transaksi Baru</ion-label>
                </a>
                <a lines="none" button routerLink="/history/step2"  routerLinkActive="active-item" >
                  <ion-label>Sudah Dikonfirmasi</ion-label>
                </a>
                <a lines="none" button routerLink="/history/step3"  routerLinkActive="active-item" >
                  <ion-label>Sudah Diproses</ion-label>
                </a>
                <a lines="none" button routerLink="/history/step4"  routerLinkActive="active-item" >
                  <ion-label>Sudah Dikirim</ion-label>
                </a>
                <a lines="none" button routerLink="/history/step5"  routerLinkActive="active-item" >
                  <ion-label>Sudah Diterima</ion-label>
                </a>
                <a lines="none" button routerLink="/history/step6"  routerLinkActive="active-item" >
                  <ion-label>Sudah Bayar</ion-label>
                </a>
                <a lines="none" button routerLink="/history/step7"  routerLinkActive="active-item" >
                  <ion-label>Sudah Ditransfer</ion-label>
                </a>
                <a lines="none" button routerLink="/history/completed"  routerLinkActive="active-item" >
                  <ion-label>Sudah Selesai</ion-label>
                </a>
                <a lines="none" button routerLink="/history/cancelled"  routerLinkActive="active-item" >
                  <ion-label>Sudah Dibatalkan</ion-label>
                </a>
              </div>
              <ion-item lines="none" button routerLink="/negotiation"  routerLinkActive="active-item" >
                <ion-icon name="chatbubbles" slot="start" color="light"></ion-icon>
                <ion-label>Negotiation</ion-label>
              </ion-item>
              <div *ngIf="negotiation_opened" class="submenu">
                <a lines="none" button routerLink="/negotiation/all"  routerLinkActive="active-item" >
                  <ion-label>Semua Negosiasi</ion-label>
                </a>
                <a lines="none" button routerLink="/negotiation/open"  routerLinkActive="active-item" >
                  <ion-label>Negosiasi Terbuka</ion-label>
                </a>
                <a lines="none" button routerLink="/negotiation/approved"  routerLinkActive="active-item" >
                  <ion-label>Negosiasi Disetujui</ion-label>
                </a>
                <a lines="none" button routerLink="/negotiation/expired"  routerLinkActive="active-item" >
                  <ion-label>Negosiasi Kadaluarsa</ion-label>
                </a>
                <a lines="none" button routerLink="/negotiation/used"  routerLinkActive="active-item" >
                  <ion-label>Negosiasi Terpakai</ion-label>
                </a>
              </div>
              <ion-item lines="none" button routerLink="/account/log"  routerLinkActive="active-item">
                <ion-icon name="file-tray-stacked" slot="start" color="light"></ion-icon>
                <ion-label>Log</ion-label>
              </ion-item>
              <ion-item lines="none" button routerLink="/account/complaint"  routerLinkActive="active-item" >
                <ion-icon name="warning" slot="start" color="light"></ion-icon>
                <ion-label>Complaint</ion-label>
              </ion-item>
              <div *ngIf="complaint_opened" class="submenu">
                <a lines="none" button routerLink="/account/complaint/all"  routerLinkActive="active-item" >
                  <ion-label>Semua Complaint</ion-label>
                </a>
                <a lines="none" button routerLink="/account/complaint/open"  routerLinkActive="active-item" >
                  <ion-label>Complaint Terbuka</ion-label>
                </a>
                <a lines="none" button routerLink="/account/complaint/completed"  routerLinkActive="active-item" >
                  <ion-label>Complaint Selesai</ion-label>
                </a>
                <a lines="none" button routerLink="/account/complaint/cancelled"  routerLinkActive="active-item" >
                  <ion-label>Complaint Dibatalkan</ion-label>
                </a>
                <a lines="none" button routerLink="/account/complaint/escalated"  routerLinkActive="active-item" >
                  <ion-label>Complaint Tereskalasi</ion-label>
                </a>
              </div>
              <ion-item lines="none" button routerLink="/account/rating"  routerLinkActive="active-item" >
                <ion-icon name="star-half" slot="start" color="light"></ion-icon>
                <ion-label>Rating</ion-label>
              </ion-item>
              <ion-item lines="none" button routerLink="/account/contact"  routerLinkActive="active-item" >
                <ion-icon name="mail" slot="start" color="light"></ion-icon>
                <ion-label>Inbox</ion-label>
              </ion-item>
            </div>

          </ion-menu-toggle>
          
        </ion-list>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main" ></ion-router-outlet>
  </ion-split-pane>
</ion-app>
