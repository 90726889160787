import { Component, OnInit } from '@angular/core';
import { LoadingController, AlertController, ModalController } from '@ionic/angular';
import { MemberService } from 'src/app/services/member.service';

export interface ResetVar{
  email: string;
}
@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss'],
})
export class ResetComponent implements OnInit {

  form_data: ResetVar = {  email : "" };

  constructor(
    private loadingCtrl: LoadingController,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private auth_service: MemberService
  ) { }

  ngOnInit() {}


  on_submit(){
    console.log( this.form_data );

    this.loadingCtrl
    .create({ keyboardClose: true, message: 'Reset Password, please wait...' })
    .then(loadingEl => {
      loadingEl.present();
      this.auth_service.member_reset_password_step1( this.form_data ).subscribe( resData => {
        console.log( "Reset Successful", resData );
        loadingEl.dismiss();
        if( resData.status == "ok" ){
          this.alertCtrl.create({
            header: "Change Password", backdropDismiss: false,
            message: "We have send the verification code to your email, use the code to change your password",
            inputs: [
              {
                name: 'code',
                placeholder: 'Verification Code ( 6 Digit )'
              },
              {
                name: 'password',
                placeholder: 'New Password',
                type: 'password'
              },
              {
                name: 'password2',
                placeholder: 'Retype Password',
                type: 'password'
              }
            ],
            buttons: [{
              text: 'Cancel',
              role: 'cancel',
              handler: data => {
                console.log('Cancel clicked');
                return true;
              }
            },
            {
              text: 'Change Password',
              handler: data => {
                console.log( data );
                console.log( resData );
                data.member_id = resData.data;

                //Start Chaging Password Section 
                this.loadingCtrl
                .create({ keyboardClose: true, message: 'Edit Password, please wait...' })
                .then(loadingEl2 => {
                  loadingEl2.present();
                  this.auth_service.member_reset_password_step2( data ).subscribe( resData2 => {
                    console.log( "Reset Step 2 Successful", resData2 );
                    loadingEl2.dismiss();
                    if( resData2.status == "ok" ){
                      this.alertCtrl.create({
                        header: "Password Changed",
                        message: "Please login using new password",
                        buttons: ["OK"]
                      }).then( alertElSuccess => {
                        alertElSuccess.present();
                      }).then( alertElSuccess => {
                        return true;
                      });
                      return true;
                    }else{
                      this.alertCtrl.create({
                        header: "Error",
                        message: resData2.status_message,
                        buttons: ['Dismiss']
                      }).then( alertElError => {
                        alertElError.present();
                      });
                      return false;
                    }
                  });
                });
                //End Changing Password Section

              }
            }]
          }).then( alertEl => {
            alertEl.present();
          }).then( alertEl => {
            this.on_close();
          });; 
        }else{
          loadingEl.dismiss();
          this.alertCtrl.create({
            header: "Error",
            message: resData.status_message,
            buttons: ['Dismiss']
          }).then( alertEl => {
            alertEl.present();
          });
        }
      });
    });
  }
  presentAlert( header, message ) {
    let alert = this.alertCtrl.create({
      header: header,
      message: message,
      buttons: ['Dismiss']
    }).then( alertEl => {
      alertEl.present();
    }); 
  }
  on_close(){
    this.modalCtrl.dismiss( null, "cancel", "ResetModal" );
  }
}
