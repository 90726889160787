<ion-toolbar [class]="header_class" color="primary">
  <ion-img style="height:40px; width:140px;" class="icon" src='/assets/logo.png'></ion-img>
  <ion-buttons slot="start" >
    <ion-menu-button ></ion-menu-button>
  </ion-buttons>

  <ion-buttons slot="primary">
    <ion-button (click)="login_penjual()" *ngIf="authService.user_signed_in == false" class="icon-text">
        <ion-icon name="log-in"></ion-icon>
        <span>Login<br>Vendor</span>
    </ion-button>
    <ion-button (click)="login_dapodik()" *ngIf="authService.user_signed_in == false" class="icon-text">
        <ion-icon name="log-in-outline"></ion-icon>
        <span>Login<br>Sekolah</span>
    </ion-button>
    <ion-button [routerLink]="['/account']" *ngIf="authService.user_signed_in" class="icon-text">
        <ion-icon name="person"></ion-icon>
        <span>Akun<br>{{ authService.member_pembeli ? "Sekolah" : "Vendor" }}</span>
    </ion-button>
    <ion-button (click)="authService.logout()" *ngIf="authService.user_signed_in" class="icon-text">
        <ion-icon name="log-out"></ion-icon>
        <span>Logout</span>
    </ion-button>
    <ion-button (click)="OpenWhatsapp()" >
      <ion-icon name="logo-whatsapp" slot="icon-only"></ion-icon>
    </ion-button>
  </ion-buttons>
</ion-toolbar>