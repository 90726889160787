<ion-header>
  <ion-toolbar color="primary">
    <ion-title>Daftar Akun Baru</ion-title>
    <ion-buttons slot="primary">
      <ion-button (click)="on_close()">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding" >
  <form (ngSubmit)="on_submit()">

    <!-- Data Login Umum Pengguna -->
    <ion-card>
      <ion-card-header>
        <ion-card-title>
          Data Login Pengguna
        </ion-card-title>
      </ion-card-header>
      <ion-card-content>
        <ion-grid>
          <ion-row *ngIf="is_pembeli == false">
            <ion-col >
              <ion-list>
                <ion-radio-group [(ngModel)]="form_data['g-3347_9']" name="g-3347_9">
                  <ion-list-header>
                    <ion-label>Type Akun</ion-label>
                  </ion-list-header>
                  <ion-item>
                    <ion-label>Individual</ion-label>
                    <ion-radio slot="start" value="3347_29"></ion-radio>
                  </ion-item>
                  <ion-item>
                    <ion-label>Badan Hukum</ion-label>
                    <ion-radio slot="start" value="3347_30"></ion-radio>
                  </ion-item>
                </ion-radio-group>
              </ion-list>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col >
              <ion-item>
                <ion-label position="floating">Nama Pengguna</ion-label>
                <ion-input required placeholder="Nama sesuai NIK"  [(ngModel)]="form_data.info_10" name="info_10" ></ion-input>
              </ion-item>
            </ion-col>
            <ion-col >
              <ion-item>
                <ion-label position="floating">NIK</ion-label>
                <ion-input required inputmode="numeric" [minlength]="nik_length" [maxlength]="nik_length" placeholder="Nomor Induk Kependudukan"  [(ngModel)]="form_data.info_2" name="info_2" ></ion-input>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-item>
                <ion-label position="floating">Email</ion-label>
                <ion-input required type="email" [(ngModel)]="form_data.email" name="email" ></ion-input>
              </ion-item>
            </ion-col>
            <ion-col>
              <ion-item>
                <ion-label position="floating">Nomor Telepon</ion-label>
                <ion-input
                  type="text"
                  [(ngModel)]="form_data.mobile_number"
                  name="mobile_number"
                  required
                ></ion-input>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-item>
                <ion-label position="floating">Password</ion-label>
                <ion-input required type="password" [(ngModel)]="form_data.password" name="password" ></ion-input>
              </ion-item>
            </ion-col>
            <ion-col>
              <ion-item>
                <ion-label position="floating">Retype Password</ion-label>
                <ion-input required type="password" [(ngModel)]="form_data.password2" name="password2" ></ion-input>
              </ion-item>
            </ion-col>
          </ion-row>
        </ion-grid>    
      </ion-card-content>
    </ion-card>
    
    <!-- Data Sekolah -->
    <ion-grid *ngIf="is_pembeli">
      <ion-row>
        <ion-col >
          <ion-item>
            <ion-label position="floating">NPSN</ion-label>
            <ion-input
              type="text"
              [(ngModel)]="form_data.info_2"
              name="info_2"
              required
            ></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col >
          <ion-item>
            <ion-label position="floating">Nama Sekolah</ion-label>
            <ion-input
              type="text"
              [(ngModel)]="form_data.name"
              name="name"
              required
            ></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col >
          <ion-item>
            <ion-label position="stacked">Bentuk Pendidikan</ion-label>
            <ion-select placeholder="Pilih Salah Satu" name="bentuk_pendidikan" type="string" [(ngModel)]="form_data['g-3347_12']">
              <ion-select-option *ngFor="let bentuk_pendidikan of global_service.bentuk_pendidikan_array" [value]="bentuk_pendidikan.group_id">{{ bentuk_pendidikan.name }}</ion-select-option>
            </ion-select>
          </ion-item>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col>
          <ion-item>
            <ion-label position="floating">NPWP</ion-label>
            <ion-input rows="6" placeholder="Input Vendor's NPWP"  [(ngModel)]="form_data.info_1" name="info_1" ></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="6" >
          <ion-item>
            <ion-label position="floating">Nama Kepala Sekolah</ion-label>
            <ion-input type="text" placeholder="Input Nama Kepala Sekolah"  [(ngModel)]="form_data.info_3" name="info_3" ></ion-input>
          </ion-item>
        </ion-col>
        <ion-col size="6" >
          <ion-item>
            <ion-label position="floating">NIP Kepala Sekolah</ion-label>
            <ion-input type="text" placeholder="Input NIP Kepala Sekolah"  [(ngModel)]="form_data.info_4" name="info_4" ></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="6" >
          <ion-item>
            <ion-label position="floating">Nama Bendahara</ion-label>
            <ion-input type="text" placeholder="Input Nama Kepala Sekolah"  [(ngModel)]="form_data.info_5" name="info_5" ></ion-input>
          </ion-item>
        </ion-col>
        <ion-col size="6" >
          <ion-item>
            <ion-label position="floating">NIP Bendahara</ion-label>
            <ion-input type="text" placeholder="Input NIP Kepala Sekolah"  [(ngModel)]="form_data.info_6" name="info_6" ></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>
    </ion-grid>

    <!-- Data Vendor -->
    <ion-card *ngIf="is_pembeli == false">
      <ion-card-header>
        <ion-card-title>
          Data Informasi {{ label_nama }}
        </ion-card-title>
      </ion-card-header>
      <ion-card-content>
        <ion-grid >
          <ion-row *ngIf="form_data['g-3347_9'] == '3347_30'">
            <ion-col >
              <ion-list>
                <ion-radio-group [(ngModel)]="form_data['g-3347_10']" name="g-3347_10">
                  <ion-list-header>
                    <ion-label>PKP?</ion-label>
                  </ion-list-header>
                  <ion-item>
                    <ion-label>PKP</ion-label>
                    <ion-radio slot="start" value="3347_32"></ion-radio>
                  </ion-item>
                  <ion-item>
                    <ion-label>Non PKP</ion-label>
                    <ion-radio slot="start" value="3347_31"></ion-radio>
                  </ion-item>
                </ion-radio-group>
              </ion-list>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-item>
                <ion-label position="stacked">Kategori Usaha</ion-label>
                <ion-select placeholder="Pilih Salah Satu" name="kategori_usaha" type="string" [(ngModel)]="form_data['g-3347_11']">
                  <ion-select-option *ngFor="let kategori_usaha of global_service.kategori_usaha_array" [value]="kategori_usaha.group_id">{{ kategori_usaha.name }}</ion-select-option>
                </ion-select>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col >
              <ion-item>
                <ion-label position="floating">Nama {{ label_nama }}</ion-label>
                <ion-input
                  type="text"
                  [(ngModel)]="form_data.name"
                  name="name"
                  required
                ></ion-input>
              </ion-item>
            </ion-col>
          </ion-row>
          
          <ion-row *ngIf="form_data['g-3347_9'] == '3347_30'">
            <ion-col >
              <ion-item>
                <ion-label position="floating">Nama Penandatangan</ion-label>
                <ion-input rows="6" placeholder="Input Nama Penandatangan"  [(ngModel)]="form_data.info_3" name="info_3" ></ion-input>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="form_data['g-3347_9'] == '3347_30'">
            <ion-col >
              <ion-item>
                <ion-label position="floating">Jabatan Penandatangan</ion-label>
                <ion-input rows="6" placeholder="Input Jabatan Penandatangan"  [(ngModel)]="form_data.info_4" name="info_4" ></ion-input>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="false">
            <ion-col >
              <ion-item>
                <ion-label position="floating">Gender</ion-label>
                <ion-select [(ngModel)]="form_data.gender" name="gender">
                  <ion-select-option value="">Select Gender</ion-select-option>
                  <ion-select-option value="male">Male</ion-select-option>
                  <ion-select-option value="female">Female</ion-select-option>
                </ion-select>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="false">
            <ion-col >
              <ion-item>
                <ion-label position="floating">Birthday</ion-label>
                <ion-datetime 
                display-format="DD MMM YYYY"
                picker-format="DD MMM YYYY"
                [(ngModel)]="form_data.date_of_birth"
                name="date-from"
                required
                #startDateCtrl="ngModel"
                ></ion-datetime>
              </ion-item>
            </ion-col>
          </ion-row>
  
          <ion-row>
            <ion-col >
              <ion-item>
                <ion-label position="floating">NPWP</ion-label>
                <ion-input inputmode="numeric" [minlength]="npwp_length" [maxlength]="npwp_length" rows="6" placeholder="Input Vendor's NPWP"  [(ngModel)]="form_data.info_1" name="info_1" ></ion-input>
              </ion-item>
            </ion-col>
            <ion-col  *ngIf="form_data['g-3347_9'] == '3347_30'" >
              <ion-item>
                <ion-label position="floating">SIUP / NIB</ion-label>
                <ion-input inputmode="numeric" [minlength]="siup_length" [maxlength]="siup_length" rows="6" placeholder="Input SIUP / NIB"  [(ngModel)]="form_data.info_5" name="info_5" ></ion-input>
              </ion-item>
            </ion-col>
          </ion-row>
  
          <ion-row>
            <ion-col >
              <ion-item>
                <ion-label position="floating">Nama Bank</ion-label>
                <ion-input rows="6" placeholder="Input Nama Bank"  [(ngModel)]="form_data.info_6" name="info_6" ></ion-input>
              </ion-item>
            </ion-col>
            <ion-col >
              <ion-item>
                <ion-label position="floating">Nama Pemilik Rekening</ion-label>
                <ion-input rows="6" placeholder="Input Nama Pemilik Rekening"  [(ngModel)]="form_data.info_7" name="info_7" ></ion-input>
              </ion-item>
            </ion-col>
          </ion-row>
  
          <ion-row>
            <ion-col >
              <ion-item>
                <ion-label position="floating">Nomor Rekening</ion-label>
                <ion-input rows="6" placeholder="Input Nomor Rekening"  [(ngModel)]="form_data.info_8" name="info_8" ></ion-input>
              </ion-item>
            </ion-col>
            <ion-col >
              <ion-item>
                <ion-label position="floating">Cabang Bank</ion-label>
                <ion-input rows="6" placeholder="Input Cabang Bank"  [(ngModel)]="form_data.info_9" name="info_9" ></ion-input>
              </ion-item>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card-content>
    </ion-card>


    <!-- Data Contact -->
    <ion-card>
      <ion-card-header>
        <ion-card-title>
          Data Alamat {{ label_nama }}
        </ion-card-title>
      </ion-card-header>
      <ion-card-content>
        <ion-grid>
          <ion-row>
            <ion-col size-xs="12" size-xl="6">
              <ion-grid>
                <ion-row>
                  <ion-col>
                    <ion-item>
                      <ion-label position="floating">Alamat</ion-label>
                      <ion-textarea (change)="set_google_map_static_url()" rows="6" placeholder="Input Full Address"  [(ngModel)]="form_data.address" name="address" ></ion-textarea>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col size-sm="6">
                    <ion-item>
                      <ion-label position="floating">Cari Kota / Nomor Kode Pos</ion-label>
                      <ionic-selectable
                        [(ngModel)]="kota"
                        [items]="kotas"
                        name="kota"
                        itemValueField="c"
                        itemTextField="c"
                        [canSearch]="true"
                        (onSearch)="on_kota_search($event)"
                        (onChange)="on_kota_changed($event)">
                      </ionic-selectable>
                    </ion-item>
                  </ion-col>
                  <ion-col size-sm="6" >
                    <ion-item>
                      <ion-label position="floating">Kodepos</ion-label>
                      <ion-input disabled readonly type="text" placeholder="Input Kodepos"  [(ngModel)]="form_data.kodepos" name="kodepos" ></ion-input>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col size-sm="6">
                    <ion-item>
                      <ion-label position="floating">Provinsi</ion-label>
                      <ion-input disabled readonly type="text" placeholder="Input Provinsi"  [(ngModel)]="form_data.provinsi" name="provinsi" ></ion-input>
                    </ion-item>
                  </ion-col>
                  <ion-col size-sm="6" >
                    <ion-item>
                      <ion-label position="floating">Kabupaten</ion-label>
                      <ion-input disabled readonly type="text" placeholder="Input Kabupaten"  [(ngModel)]="form_data.kabupaten" name="kabupaten" ></ion-input>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col size-sm="6">
                    <ion-item>
                      <ion-label position="floating">Kecamatan</ion-label>
                      <ion-input disabled readonly type="text" placeholder="Input Kecamatan"  [(ngModel)]="form_data.kecamatan" name="kecamatan" ></ion-input>
                    </ion-item>
                  </ion-col>
                  <ion-col size-sm="6" >
                    <ion-item>
                      <ion-label position="floating">Kelurahan</ion-label>
                      <ion-input disabled readonly type="text" placeholder="Input Kelurahan"  [(ngModel)]="form_data.kelurahan" name="kelurahan" ></ion-input>
                    </ion-item>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-col>
            <ion-col size-xs="12" size-xl="6">
              <ion-grid>
                <ion-row>
                  <ion-col size-sm="6">
                    <ion-item (click)="on_open_geolocation()" style="cursor:'pointer';">
                      <ion-label position="floating">Latitude</ion-label>
                      <ion-input readonly type="text" placeholder="Input Latitude"  [(ngModel)]="form_data.latitude" name="latitude" ></ion-input>
                    </ion-item>
                  </ion-col>
                  <ion-col size-sm="6" >
                    <ion-item (click)="on_open_geolocation()" style="cursor:'pointer';">
                      <ion-label position="floating">Longitude</ion-label>
                      <ion-input readonly type="text" placeholder="Input Longitude"  [(ngModel)]="form_data.longitude" name="longitude" ></ion-input>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row *ngIf="form_data.latitude != 0 && form_data.longitude != 0">
                  <ion-col>
                    <ion-img (click)="on_open_geolocation()" [src]="google_map_static_url" style="cursor:'pointer';">
                    </ion-img>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-col>
          </ion-row>
  
          <ion-row *ngIf="false">
            <ion-col size-sm="6">
              <ion-item>
                <ion-label position="floating">Kode Destinasi</ion-label>
                <ion-input readonly [(ngModel)]="form_data.destination_code" name="destination_code" type="string"></ion-input>
              </ion-item>
            </ion-col>
            <ion-col size-sm="6">
              <ion-item>
                <ion-label position="floating">Kode Origin ( Untuk Vendor )</ion-label>
                <ion-input readonly [(ngModel)]="form_data.origin_code" name="origin_code" type="string"></ion-input>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="false">
            <ion-col>
              <ion-item>
                <ion-label position="floating">Page Category ID</ion-label>
                <ion-input rows="6" placeholder="Page Category Token"  [(ngModel)]="form_data.page_category_public_token" name="page_category_public_token" ></ion-input>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="false">
            <ion-col>
              <ion-item>
                <ion-label position="floating">Gender</ion-label>
                <ion-select [(ngModel)]="form_data.gender" name="gender">
                  <ion-select-option value="">Select Gender</ion-select-option>
                  <ion-select-option value="male">Male</ion-select-option>
                  <ion-select-option value="female">Female</ion-select-option>
                </ion-select>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="false">
            <ion-col>
              <ion-item>
                <ion-label position="floating">Birthday</ion-label>
                <ion-datetime 
                display-format="DD MMM YYYY"
                picker-format="DD MMM YYYY"
                [(ngModel)]="form_data.date_of_birth"
                name="date-from"
                required
                #startDateCtrl="ngModel"
                ></ion-datetime>
              </ion-item>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card-content>
    </ion-card>

    <!-- Data File -->
    <ion-card>
      <ion-card-header>
        <ion-card-title>
          Upload Dokumen Penting
        </ion-card-title>
      </ion-card-header>
      <ion-card-content>
        <ion-grid>
          <ion-row>
            <ion-col>
              <ion-item>
                <ion-label position="stacked">
                  File NIK
                </ion-label>
                <ion-input type="file" accept="image/*" (change)="on_file_selected('nik', $event)" multiple="true"></ion-input>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-item>
                <ion-label position="stacked">
                  File NPWP
                </ion-label>
                <ion-input type="file" accept="image/*" (change)="on_file_selected('npwp', $event)" multiple="true"></ion-input>
              </ion-item>
            </ion-col>
            </ion-row>
            <ion-row>
            <ion-col *ngIf="form_data['g-3347_9'] == '3347_30'">
              <ion-item>
                <ion-label position="stacked">
                  File NIB / SIUP
                </ion-label>
                <ion-input type="file" accept="image/*" (change)="on_file_selected('siup', $event)" multiple="true"></ion-input>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col *ngIf="form_data['g-3347_9'] == '3347_30'">
              <ion-item>
                <ion-label position="stacked">
                  File TDP
                </ion-label>
                <ion-input type="file" accept="image/*" (change)="on_file_selected('tdp', $event)" multiple="true"></ion-input>
              </ion-item>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card-content>
    </ion-card>
    <ion-button type="submit" expand="block" color="primary">Sign UP</ion-button>
  </form>
</ion-content>