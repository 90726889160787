import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-member-widget',
  templateUrl: './member-widget.component.html',
  styleUrls: ['./member-widget.component.scss'],
})
export class MemberWidgetComponent implements OnInit {

  member_image: string = "https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y";
  member_welcome: string = "Akun Vendor";
  
  public get member_name():string{
    return this.auth_service.member_name;
  }

  constructor(
    public auth_service: AuthenticationService
  ) { 

  }

  ngOnInit() {
    if( this.auth_service.member_pembeli ){
      this.member_welcome = "Akun Satdik";
      this.member_image = "https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7740?d=identicon&f=y"
    }
  }


  UploadImage(){
    
  }
}
