import { Injectable } from '@angular/core';
import { member, member_location, result_member, result_member_location } from './interfaces.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { Observable, of, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IonicSelectableComponent } from 'ionic-selectable';

@Injectable({
  providedIn: 'root'
})
export class MemberService {

  constructor( private http: HttpClient ) { }

  /**
   * @method edit
   * We will use this edit our member, however remember, if this is not CMS or this is not STAFF LOGIN then we must always
   * EDIT logged in data ( can never edit other members data just because we have logged in to our account )
   */
  edit( _data: member ){
    //console.log( _data );
    return this.http.put<any>( environment.api_url + "/member", _data ).pipe(
      tap( resData => {
        console.log( resData );
        if( resData && resData.status && resData.status == "ok" ){
          
        }
      }),
      catchError(this.handleError('MEMBER EDIT PIPE ERROR', []))
    );
  }

  /**
   * @method edit_password
   * This is even more strict, we will only ever allowed to edit our own password, not even staff can edit members password
   */
  edit_password( _data: any ){
    //console.log( _data );
    return this.http.put<any>( environment.api_url + "/member/password", _data ).pipe(
      tap( resData => {
        console.log( resData );
        if( resData && resData.status && resData.status == "ok" ){
          
        }
      }),
      catchError(this.handleError('MEMBER EDIT PIPE ERROR', []))
    );
  }

  
  reset( data ): Observable<any>{
    return this.http.post<any>( environment.api_url + '/member/reset_password', data ).pipe(
      tap( _ => this.log("AuthService.Reset TAPPED")),
      catchError( this.handleError("Reset", []))
    );
  }

  register (data): Observable<any> {
    return this.http.post<any>( environment.api_url + '/member/register', data)
      .pipe(
        tap(_ => this.log('Register')),
        catchError(this.handleError('Register', []))
      );
  }

  member_load() {
    //console.log( _data );
    return this.http.get<any>( environment.api_url + "/member" ).pipe(
      tap( resData => {
        console.log( resData );
        if( resData && resData.status && resData.status == "ok" ){
          
        }
      }),
      catchError(this.handleError('MEMBER EDIT PIPE ERROR', []))
    );
  }
  //This will be unused for now
  member_reset_password( _data ) {
    //console.log( _data );
    return this.http.put<any>( environment.api_url + "/member/reset_password", _data ).pipe(
      tap( resData => {
        console.log( resData );
        if( resData && resData.status && resData.status == "ok" ){
          
        }
      }),
      catchError(this.handleError('MEMBER EDIT PIPE ERROR', []))
    );
  }
  member_reset_password_step1( _data ) {
    //console.log( _data );
    return this.http.put<any>( environment.api_url + "/member/reset_password_step1", _data ).pipe(
      tap( resData => {
        console.log( resData );
        if( resData && resData.status && resData.status == "ok" ){
          
        }
      }),
      catchError(this.handleError('MEMBER EDIT PIPE ERROR', []))
    );
  }
  member_reset_password_step2( _data ) {
    //console.log( _data );
    return this.http.put<any>( environment.api_url + "/member/reset_password_step2", _data ).pipe(
      tap( resData => {
        console.log( resData );
        if( resData && resData.status && resData.status == "ok" ){
          
        }
      }),
      catchError(this.handleError('MEMBER EDIT PIPE ERROR', []))
    );
  }
  member_register( _data ) {
    //console.log( _data );
    return this.http.post<any>( environment.api_url + "/member/signup", _data ).pipe(
      tap( resData => {
        if( resData && resData.status && resData.status == "ok" ){
          
        }
      }),
      catchError(this.handleError('MEMBER EDIT PIPE ERROR', []))
    );
  }



  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a HeroService message with the MessageService */
  private log(message: string) {
    console.log(message);
  }




  /** 
   * Member Filter ( Can Search by Name / Location ) 
   * Because we will always return first row as "All Member From Kabupaten, Provinsi"
   * This is different then our member_load which only load current logged-in member
   * this member filter will filter ONLY member which page_category_id is within the Marketplace System
  */
  public members: member[];
  public selected_members: member[];
  member_subscription: Subscription;
  search( _filters ){
    let params = new HttpParams();
    for (var key in _filters) {
      params = params.set( key, _filters[key] );
    }
    return this.http.get<result_member>( environment.api_url + "/member/search", 
      { params: params } 
    );
  }
  on_member_search( event: {
    component: IonicSelectableComponent,
    text: string
    } ){
    let text = event.text.trim().toLowerCase();
    event.component.startSearch();

    // Close any running subscription.
    if (this.member_subscription) {
      this.member_subscription.unsubscribe();
    }


    this.member_subscription = this.search( { keyword: text }).subscribe( _members => {
      // Subscription will be closed when unsubscribed manually.
      if (this.member_subscription.closed) {
        return;
      }
      if( typeof this.selected_members != "undefined" && this.selected_members.length > 0 ){
        //Remove the selected_tags from the _groups
        for( let j = 0 ; j < this.selected_members.length ; j++ ){
          let member_found: boolean = false;
          for( let i = 0 ; i < _members.data.length ; i++ ){
            if( _members.data[i].member_id == this.selected_members[j].member_id ){
              for( let key in _members.data[i] ){
                member_found = true;
                this.selected_members[j][key] = _members.data[i][key];
              }
            }
          }
          if( !member_found ){
            _members.data.unshift( this.selected_members[j] );
          }
        }
      }

      //Generated Name
      for( let i = 0 ; i < _members.data.length ; i++ ){
        if( _members.data[i].page_category_id == "provinsi" ){
          _members.data[i].name_generated = "Semua Vendor dari Provinsi " + _members.data[i].provinsi;
        }else if( _members.data[i].page_category_id == "kabupaten" ){
          _members.data[i].name_generated = "Semua Vendor dari Kabupaten " + _members.data[i].kabupaten + ", " + _members.data[i].provinsi;
        }else{
          _members.data[i].name_generated = _members.data[i].name + " - " + _members.data[i].kabupaten + ", " + _members.data[i].provinsi;
        }
      }
      console.log( _members.data );
      this.members = _members.data;
      event.component.endSearch();
    });
  }

  public member_location: member_location[];
  public selected_member_location: member_location[];
  location_search( _filters ){
    let params = new HttpParams();
    for (var key in _filters) {
      params = params.set( key, _filters[key] );
    }
    return this.http.get<result_member_location>( environment.api_url + "/member/location", 
      { params: params } 
    );
  }
  on_member_location_search( event: {
    component: IonicSelectableComponent,
    text: string
    } ){
    let text = event.text.trim().toLowerCase();
    event.component.startSearch();

    // Close any running subscription.
    if (this.member_subscription) {
      this.member_subscription.unsubscribe();
    }


    this.member_subscription = this.location_search( { keyword: text }).subscribe( _members => {
      // Subscription will be closed when unsubscribed manually.
      if (this.member_subscription.closed) {
        return;
      }
      if( typeof this.selected_member_location != "undefined" && this.selected_member_location.length > 0 ){
        //Remove the selected_tags from the _groups
        for( let j = 0 ; j < this.selected_member_location.length ; j++ ){
          let member_found: boolean = false;
          for( let i = 0 ; i < _members.data.length ; i++ ){
            if( _members.data[i].id == this.selected_member_location[j].id ){
              for( let key in _members.data[i] ){
                member_found = true;
                this.selected_member_location[j][key] = _members.data[i][key];
              }
            }
          }
          if( !member_found ){
            _members.data.unshift( this.selected_member_location[j] );
          }
        }
      }

      //Generated Name
      let location_provinsi: string[] = [];
      let location_kabupaten: string[] = [];
      let location_kecamatan: string[] = [];
      this.member_location = [];
      for( let i = 0 ; i < _members.data.length ; i++ ){
        let provinsi = _members.data[i].provinsi;
        let kabupaten = _members.data[i].kabupaten;
        let kecamatan = _members.data[i].kecamatan;
        if( location_provinsi.indexOf( provinsi ) == -1 ){
          //Add Vendor dari Provinsi
          location_provinsi.push( provinsi );
          this.member_location.push( { name: "Provinsi " + provinsi, id: provinsi, type: "provinsi" } );
        }
        if( location_kabupaten.indexOf( kabupaten ) == -1 ){
          location_kabupaten.push( kabupaten );
          this.member_location.push( { name: "Kabupaten " + kabupaten + ", " + provinsi , id: kabupaten, type: "kabupaten" } );
        }
        if( location_kecamatan.indexOf( kecamatan ) == -1 ){
          location_kecamatan.push( kecamatan );
          this.member_location.push( { name: "Kecamatan " + kecamatan + ", " + kabupaten + ", " + provinsi , id: kecamatan, type: "kecamatan" } );
        }

        
      }
      console.log( this.member_location );
      event.component.endSearch();
    });
  }



}
