import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ModalController, LoadingController, AlertController } from '@ionic/angular';
import { MemberService } from 'src/app/services/member.service';
import { file_image, result_file_image_get, shipping_location, shipping_origin } from 'src/app/services/interfaces.service';
import { Subscription } from 'rxjs';
import { IonicSelectableComponent } from 'ionic-selectable';
import { ShippingService } from 'src/app/services/shipping.service';
import { StaticService } from 'src/app/services/static.service';
import { GlobalService } from 'src/app/services/global.service';
import { GoogleMapsComponent } from 'src/app/components/google-maps/google-maps.component';
import { environment } from 'src/environments/environment';
import { Event } from '@angular/router';
import { single } from 'rxjs/operators';
import { FileService, file_parent } from 'src/app/services/file.service';

interface CountryData{
  code?: number,
  name?: string
}
interface RegisterData{
  username?: string;
  name?: string;
  password?: string;
  password2?: string;
  email?: string;
  country?: number;
  mobile_number?: string;
  gender?: string;
  info_1?: string; //NPWP
  info_2?: string; //NIK
  info_3?: string; //Nama Penandatangan ( hanya untuk perusahaan )
  info_4?: string; //Jabatan Penandatangan ( hanya untuk perusahaan )
  info_5?: string; //SIUP / NIB ( hanya untuk Corporate )
  info_6?: string; //Nama Bank
  info_7?: string; //Nama Pemilik Bank
  info_8?: string; //Nomor Rekening
  info_9?: string; //Cabang Bank
  info_10?: string; //Nama Pengguna ( Tambahan terakhir )
  member_referral_id?: string;
  date_of_birth?: String;
  dob_date?:number;
  dob_month?:number;
  dob_year?:number;

  page_category_id?: string;
  page_category_public_token?: string;

  zone?: number;

  address?: string;
  provinsi?: string;
  kabupaten?: string;
  kecamatan?: string;
  kelurahan?: string;
  kodepos?: string;
  destination_code?: string;
  origin_code?: string;

  latitude?: number;
  longitude?: number;

  file_data?: file_image[];
}

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {

  is_pembeli: boolean = false;

  get label_nama(): string {
    let nama: string = "";
    if( this.form_data["g-3347_9"] == "3347_29" ){
      nama = "Toko";
    }else{
      nama = "Perusahaan";
    }
    return nama;
  }
  form_data: RegisterData = { 
    country: 62, gender: "", 
    page_category_id: "3347_39", 
    page_category_public_token: "2c357e58dc49fa482d8bfbd3c76a367c", 
    email: "penyedia7@siplahmasmedia.co.id", 
    password: "Penyedia123!@#",
    password2: "Penyedia123!@#",
    name: "Vendor 7",
    address: "Pelepah Kuning Blok WA-1 / 23",
    mobile_number: "081510030017",
    info_1: "341842060043007",
    info_2: "3172062402830007",
    info_3: "Taufik 7",
    info_4: "CTO",
    info_5: "1234567890125",
    info_6: "BCA",
    info_7: "Taufik 7",
    info_8: "2270156642",
    info_9: "BCA Kelapa Gading",
    info_10: "Vendor 7 - Pengguna"
    /*,
    latitude: -6.164451400000001,
    longitude: 106.9022733
    /* */
  };
  countries: CountryData[] = [ {code:62, name:"Indonesia"} ];
  constructor(
    private loadingCtrl: LoadingController,
    private alertCtrl: AlertController,
    private auth_service: MemberService,
    private modalCtrl: ModalController,
    private shipping_service: ShippingService,
    public global_service: GlobalService,
    private file_service: FileService
  ) {
    
  }


  ngOnInit() {
    this.form_data.date_of_birth = new Date().toISOString();
    if( this.is_pembeli ){
      this.form_data.page_category_id = "3347_38";
      this.form_data.page_category_public_token = "d7ae42332cba69efd941eae4ecbe30f0";
    }else{
      this.form_data["g-3347_9"] = "3347_29";
      this.form_data["g-3347_10"] = "3347_32";
      this.form_data["g-3347_11"] = "3347_34";
    }
    this.set_google_map_static_url();
  }

  npwp_length: number = 15;
  nik_length: number = 16;
  siup_length: number = 13;
  verify_form(): string[]{
    let error_message: string[] = [];

    if( this.form_data.email.length < 3 || this.form_data.email.indexOf("@") == -1 ){
      error_message.push( "Isi email yang valid" );
    }

    if( this.form_data.mobile_number.length < 7  ){
      error_message.push( "Isi nomor handphone yang valid" );
    }

    if( this.form_data.info_1.length != this.npwp_length){
      error_message.push( "NPWP harus terdiri dari " + this.npwp_length + " angka" );
    }
    if( this.form_data["g-3347_9"] == "3347_29" ){
      //Pendaftar Individu
      if( this.form_data.info_2.length != this.nik_length ){
        error_message.push( "NIK harus terdiri dari " + this.nik_length + " angka" );
      }
      if( this.file_not_yet_uploaded.length < 2 ){
        error_message.push( "File NIK & NPWP wajib ada" );
      }
    }else{
      //Pendaftar Corporate
      if( this.form_data.info_3.length < 1 ){
        error_message.push( "Nama Penanggung Jawab tidak boleh kosong" );
      }
      if( this.form_data.info_4.length < 1 ){
        error_message.push( "Jabatan Penanggung Jawab tidak boleh kosong" );
      }
      if( this.form_data.info_5.length != this.siup_length ){
        error_message.push( "NIB / SIUP harus terdiri dari " + this.siup_length + " angka" );
      }
      if( this.file_not_yet_uploaded.length < 4 ){
        error_message.push( "File NIK, NPWP, SIUP/NIB & TDP wajib ada" );
      }
    }

    if( this.form_data.info_6.length < 1 ){
      error_message.push( "Nama Bank tidak boleh kosong" );
    }
    if( this.form_data.info_7.length < 1 ){
      error_message.push( "Nama Pemilik Rekening tidak boleh kosong" );
    }
    if( this.form_data.info_8.length < 1 ){
      error_message.push( "Nomor Rekening tidak boleh kosong" );
    }
    if( this.form_data.info_9.length < 1 ){
      error_message.push( "Cabang Bank tidak boleh kosong" );
    }

    if( this.form_data.address.length < 3 ){
      error_message.push( "Isi lokasi alamat yang benar" );
    }

    if( this.form_data.latitude == 0 || isNaN( this.form_data.latitude )){
      error_message.push( "Geolokasi Lintas & Bujur tidak boleh kosong, click peta untuk meletakan titik pada lokasi" );
    }
    
    if( this.form_data.info_10.length < 1 ){
      error_message.push( "Nama tidak boleh kosong" );
    }
    
    
    
    return error_message;
  }
  on_submit(){
    console.log( JSON.stringify(this.form_data) );
    let verify_form_message: string[] = this.verify_form();
    if( verify_form_message.length > 0 ){
      //20210612 :: Ada error

      this.alertCtrl.create({
        header: "Isi seluruh kolom isian dengan baik",
        message: verify_form_message.join(", "),
        buttons: ['Ok']
      }).then( alertEl => {
        alertEl.present();
      });
      return false;
    }
    this.loadingCtrl
    .create({ keyboardClose: true, message: 'Registering new member, please wait...' })
    .then(loadingEl => {
      loadingEl.present();
      this.auth_service.member_register( this.form_data ).subscribe( resData => {
        console.log( "Register Successful", resData );
        loadingEl.dismiss();
        if( resData.status == "ok" ){
          if( resData.data.success.length > 0 ){
            this.target_parent.primary_id = resData.data.success[0]["id"];
            this.target_parent.upload_token = resData.data.success[0]["code"].split(".")[1];
            console.log( "Register Successful Check", this.target_parent, resData.data.success );
            this.on_file_upload_execute();
          }else if( resData.data.error.length > 0 ){
            this.alertCtrl.create({
              header: "Registration Error",
              message: resData.data.error[0].message,
              buttons: ['Dismiss']
            }).then( alertEl => {
              alertEl.present();
            }); 
          }else{
            this.alertCtrl.create({
              header: "Registration Error",
              message: "Member registration failed!",
              buttons: ['Dismiss']
            }).then( alertEl => {
              this.on_close();
            });
          }
        }else{
          loadingEl.dismiss();
          this.alertCtrl.create({
            header: "Registration Error",
            message: resData.status_message,
            buttons: ['Dismiss']
          }).then( alertEl => {
            alertEl.present();
          });
        }
      });
    });
    
  }
  on_close(){
    this.modalCtrl.dismiss( null, "cancel", "RegisterModal" );
  }



  //20200728 :: On Search Change of Nama Kota / Nomor Kode Post

  origin_array: shipping_origin[] = [];
  LoadLocation(){
    this.loadingCtrl
    .create({ keyboardClose: true, message: 'Searching...' })
    .then(loadingEl => {
      loadingEl.present();
      this.shipping_service.Get( this.form_data.origin_code, this.form_data.provinsi
        , this.form_data.kabupaten, 
        this.form_data.kecamatan, 
        this.form_data.kelurahan ).subscribe( result => {
        loadingEl.dismiss();
        //Do nothing, we just want to UPDATE PRICES
      });
    });
  }

  kotas: shipping_location[];
  kota: shipping_location;
  kota_subscription: Subscription;
  on_kota_search(event: {
    component: IonicSelectableComponent,
    text: string
  }, instant: boolean = false ) {

    
    let text = event.text.trim().toLowerCase();
    if( instant == false ){
      event.component.startSearch();

      // Close any running subscription.
      if (this.kota_subscription) {
        this.kota_subscription.unsubscribe();
      }

      if (!text) {
        // Close any running subscription.
        if (this.kota_subscription) {
          this.kota_subscription.unsubscribe();
        }

        event.component.items = [];
        event.component.endSearch();
        return;
      }
    }

    this.kota_subscription = this.shipping_service.Search( text ).subscribe( locations => {
      // Subscription will be closed when unsubscribed manually.
      if (this.kota_subscription.closed) {
        return;
      }
      if( instant ){
        let quick_data = locations.data[0];
        if( locations.data.length > 1 ){
          //If more then one then try to search using our c
          for( let single_location of locations.data ){
            if( single_location.c.indexOf( this.form_data.kelurahan ) >= 0 ){
              quick_data = single_location;
            }
          }
        }
        this.on_kota_changed({
          component: null,
          value: quick_data
        });
      }else{
        event.component.items = locations.data;
        event.component.endSearch();
      }
      
    });
  }

  on_kota_changed(event: {
    component: IonicSelectableComponent,
    value: shipping_location
  }){
    let kota_arr = event.value.c.split(", ");
    this.form_data.provinsi = kota_arr[3].trim();
    this.form_data.kabupaten = kota_arr[2].trim();
    this.form_data.kecamatan = kota_arr[1].trim();
    let kelurahan = kota_arr[0].trim().split(" (");
    this.form_data.kelurahan = kelurahan[0];
    this.form_data.kodepos = kelurahan[1].split(")")[0];
    this.form_data.destination_code = event.value.cd;
    this.set_google_map_static_url();
    this.LoadLocation();
  }

    //20210520 :: on_open_geolocation 
    google_map_static_url: string = "/assets/no-image.jpg";
    set_google_map_static_url(){
      let url: string = "/assets/no-image.jpg";
      let zoom: number = 16;
      let size: string = "400x300";
      let center: string = "";
      let marker: string = "";
      if( this.form_data.latitude && this.form_data.latitude != 0 && this.form_data.longitude && this.form_data.longitude != 0 ){
        zoom = 16;
        marker = this.form_data.latitude + "," + this.form_data.longitude;
      }else if( ( this.form_data.address && this.form_data.address.length > 5 ) || ( this.form_data.kodepos && this.form_data.kodepos.length > 2 ) ){
        zoom = 13;
        if( this.form_data.address && this.form_data.address.length > 5 ){
          zoom = 15;
          center += this.form_data.address;
        }
        if( this.form_data.kodepos && this.form_data.kodepos != "" ){
          if( center != "" ){
            center += " , ";
          }
          center += this.form_data.provinsi + " , " + this.form_data.kabupaten + " , " + this.form_data.kecamatan + " , " + this.form_data.kelurahan + " , " + this.form_data.kodepos;
        }
      }
      if( center != "" || marker != "" ){
        url = "https://maps.googleapis.com/maps/api/staticmap?key=" + environment.gmap_api_key + 
        "&zoom=" + zoom + "&size=" + size;
        if( marker != "" ){
          url += "&markers=color:red%7C" + marker;
        }else{
          url += "&center=" + center;
        }
      }
      if( this.google_map_static_url != url ){
        //Static URL Changes, we must update :|
        this.google_map_static_url = url;
      }
    }
    on_open_geolocation(){
      //Default on our MASMEDIA Location
      let location = {
        selected_latitude: 0,
        selected_longitude: 0, 
        selected_address: ""
      };
      if( this.form_data.latitude && this.form_data.latitude != 0 && this.form_data.longitude && this.form_data.longitude != 0 ){
        location["selected_latitude"] = this.form_data.latitude;
        location["selected_longitude"] = this.form_data.longitude;
      }

      if( this.form_data.kodepos && this.form_data.kodepos != "" ){
        location["selected_address"] = this.form_data.provinsi + " , " + this.form_data.kabupaten + " , " + this.form_data.kecamatan + " , " + this.form_data.kelurahan + " , " + this.form_data.kodepos;
      }

      if( this.form_data.address && this.form_data.address != "" ){
        if( location["selected_address"] != "" ){
          location["selected_address"] += ", ";
        }
        location["selected_address"] += this.form_data.address;
      }
      console.log( location );
      this.modalCtrl.create( {
        component: GoogleMapsComponent,
        componentProps: location,
        id: "GoogleMap"} 
      ).then( modalEl =>{
        modalEl.present();
        modalEl.onDidDismiss()
        .then((data) => {
          if( data.role == "ok" ){
            this.form_data.latitude = data.data.latitude;
            this.form_data.longitude = data.data.longitude;
            if( this.form_data.kodepos != data.data.kodepos ){
              this.form_data.kelurahan = data.data.kelurahan;
              //Update our prov, kab, kec, kel, kodepos
              console.log( "UPDATE Paksa" );
              this.on_kota_search(
                { component: null, text: data.data.kodepos }, true 
              )
            }
            this.set_google_map_static_url();
          }
        });
      });
    }



    //Simpler File Select and Upload?
    file_arr: File[];
    on_file_selected( _type: string, _event: any ){
      let file = _event.target.files[0];
      //Remove all file if it is the same type
      this.file_not_yet_uploaded = this.file_not_yet_uploaded.filter( function( value, index, arr ){ return value.upload_type != _type; });
      this.create_not_yet_uploaded( file, file.name, "false", _type );
    }

    //Target Parent ID is generated after successful Register
    //This is one of the primary reason why we need to wait for generation completed before anything else 
    //After registered the primary_id will be setted to the newly generated member_id and also the temporary_registered_token
    private target_parent: file_parent = {
      cms_type: "member",
      edit_type: "page",
      primary_id: "",
      upload_token: ""
    }; 
    public file_not_yet_uploaded: file_image[] = [];
    public file_already_uploaded: file_image[] = [];
    create_not_yet_uploaded( _file: Blob, _file_name: string, _force_jpg: string, _upload_type: string = "" ){
      let new_file: file_image = {
        file_data_id: Math.random().toString(),
        file_name: _file_name,
        image_url: "",
        file_blob: _file, uploading: false, force_jpg: _force_jpg,
        upload_type: _upload_type
      };
      let reader = new FileReader();
      reader.onload = (event:any) => {
        new_file.file_blob_url = event.target.result;
      }
      reader.readAsDataURL( new_file.file_blob );
      this.file_not_yet_uploaded.push( new_file );
    }

    //This will upload 1 file at a time
    on_file_upload_execute(){
      //20210614 :: Set our member_id and account_token to our registered member token temporarily
      if( this.file_not_yet_uploaded.length > 0 ){
        let target_file = this.file_not_yet_uploaded[0];
        this.loadingCtrl
          .create({ keyboardClose: true, message: 'Uploading ' + target_file.file_name  + ', please wait...' })
          .then(loadingEl => {
            loadingEl.present();
            this.file_service.uploadImage( this.target_parent, target_file ).subscribe(
              ( result : result_file_image_get ) => {
              loadingEl.dismiss();
              if( result.data.success.length > 0 && result.data.uploaded.length > 0 ){
                this.file_already_uploaded.push(result.data.uploaded[0]);
              }
              this.file_not_yet_uploaded.shift();
              if( this.file_not_yet_uploaded.length > 0 ){
                this.on_file_upload_execute();
              }else{
                this.file_uploaded();
              }
            }
          );
          }
        );
        
      }
    }

    file_uploaded(){
      //File finish uploading, for register, we need to close this
      this.alertCtrl.create({
        header: "Registration Successful",
        message: "Kami sudah mengirimkan sebuah link verifikasi ke email anda, click link tersebut untuk melakukan verifikasi akun email anda",
        buttons: ['Dismiss']
      }).then( alertEl => {
        alertEl.present().then( _ => {
          //20210611 :: Stop closing the window, so that we can do this multiple time
          this.on_close();
        });
      });
    }

}
