export const environment = {
  production: true,
  /*
  api_client_id: "3347",
  base_url: "https://api.siplahmasmedia.co.id/",
  api_url: "https://api.siplahmasmedia.co.id/3347",
  external_url: "https://api.siplahmasmedia.co.id/3347/external",
  siplah_url: "https://uji.siplah.kemdikbud.go.id",
  app_id: "45041481-2b69-4333-b9ce-56e44e34c38e",
  /* */
  
  api_client_id: "3347dev",
  base_url: "https://api-uat.siplahmasmedia.co.id/",
  api_url: "https://api-uat.siplahmasmedia.co.id/3347dev",
  external_url: "https://api-uat.siplahmasmedia.co.id/3347dev/external",
  siplah_url: "https://uji.siplah.kemdikbud.go.id",
  app_id: "01d3f03e-5e26-4884-a1d3-4cf5ae290307",
  /* */
  /*app_id: "45041481-2b69-4333-b9ce-56e44e34c38e",*/
  /*api_url: "http://localhost/3347",*/

  whatsapp: "whatsapp://send?phone=6281510030011",
  token_key: "3535FF49D4046E095993B030085CA221487DC74BB5B981FD1D434909BB9ED64E",
  gmap_api_key: 'AIzaSyAZusERnw54Ln7qUqxgwQcYBK_m1iBslUw' /* For our Browser */
};
