<ion-header>
  <ion-toolbar transparent>
    <ion-title>Login Sebagai {{ is_pembeli ? "Sekolah" : "Vendor" }}</ion-title>
    <ion-buttons slot="primary" color="primary">
      <ion-button (click)="on_close()">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="background" fullscreen>
  
  <form #f="ngForm" (ngSubmit)="onLogin(f)">
    <ion-grid>
      <ion-row>
        <ion-col class="align_center">
          <!-- <ion-img class="icon" src='/assets/auth-icon.png'></ion-img> -->
          <ion-item class="form_username">
            <ion-icon name="person" slot="start"></ion-icon>
            <ion-input placeholder="Email Address" required name="username" type="username" (keyup.enter)="onLogin( null )" [(ngModel)]="authentication.username" ></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-item class="form_password">
            <ion-icon name="key" slot="start"></ion-icon>
            <ion-input placeholder="Password" required password minlength="6" name="password" type="password" (keyup.enter)="onLogin( null )" [(ngModel)]="authentication.password"></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size-sm="6" size="12">
          <ion-button [disabled]="!f.valid" (click)="onLogin( null )" color="primary" expand="block">
            Sign In
          </ion-button>
        </ion-col>
        <ion-col size-sm="6" size="12">
          <ion-button type="button" color="secondary" (click)="onReset()" expand="block" >
            Reset Password
          </ion-button>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <!-- <ion-img src='/assets/auth-banner.png'></ion-img> -->
          <ion-button type="button" color="primary" (click)="onRegister()" expand="block">
            Sign Up
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
</ion-content>