/**
 * This class contains all of the shared general functions
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { SmartFormComponent } from './smart-form/smart-form.component';
import { FormsModule } from '@angular/forms';
import { IonicSelectableModule } from 'ionic-selectable';
import { GoogleMapsComponent } from './google-maps/google-maps.component';
import { TableComponent } from './table/table.component';
import { SubheaderComponent } from './subheader/subheader.component';
import { MemberWidgetComponent } from './member-widget/member-widget.component';
import { SingleCartComponent } from './single-cart/single-cart.component';
import { SelectItemComponent } from './select-item/select-item.component';
import { SingleItemComponent } from './single-item/single-item.component';
import { SingleItemSelectionComponent } from './single-item-selection/single-item-selection.component';

@NgModule({
  declarations: [ 
    FooterComponent, 
    HeaderComponent, 
    SubheaderComponent, 
    MemberWidgetComponent, 
    SmartFormComponent, 
    GoogleMapsComponent, 
    TableComponent, 
    SingleCartComponent, 
    SelectItemComponent,
    SingleItemSelectionComponent,
    SingleItemComponent
  ],
  imports: [
    CommonModule, IonicModule, RouterModule, FormsModule, IonicSelectableModule
  ],
  exports: [
    FooterComponent, 
    HeaderComponent, 
    SubheaderComponent, 
    MemberWidgetComponent, 
    SmartFormComponent, 
    GoogleMapsComponent, 
    TableComponent, 
    SingleCartComponent,
    SelectItemComponent,
    SingleItemSelectionComponent,
    SingleItemComponent
  ]
})
export class ComponentsModule { 

}
