<ion-header>
  <ion-toolbar transparent>
    <ion-title>Pilih Geolokasi</ion-title>
    <ion-buttons slot="primary" color="primary">
      <ion-button (click)="on_close()">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <input
      id="pac-input"
      class="controls"
      type="text"
      placeholder="Search Box"
    />
  <div id="map"></div>
</ion-content>
<ion-footer>
  
  <ion-toolbar >
    <ion-title class="subtitle">{{ selected_latitude }} , {{ selected_longitude }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="on_current_location()" color="primary">
        <ion-icon name="location-outline"></ion-icon>
        Lokasi Saat Ini
      </ion-button>
      <ion-button (click)="on_select_location()" color="primary" fill="solid">
        <ion-icon name="checkmark-circle-outline"></ion-icon>
        OK
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
